import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Toast from "./components/Toast";
import { useSession } from "./context/SessionContext";
import { useAppSelector } from "./hooks/useRedux";
import NotFoundPage from "./pages/404";
import { Login, Register, ResendEmailConfirmation } from "./pages/Auth";
import EmailConfirmation from "./pages/Auth/EmailConfirmation";
import ResetNewPassword from "./pages/Auth/ResetNewPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Coin from "./pages/Coin";
import { CoinList } from "./pages/CoinList";
import Dashboard from "./pages/Dashboard";
import History from "./pages/History";
import DepositHistory from "./pages/History/DepositHistory";
import WithdrawalHistory from "./pages/History/WithdrawalHistory";
import Home from "./pages/Home";
import { AuthLayout, MainLayout } from "./pages/Layout";
import CoinLayout from "./pages/Layout/CoinLayout";
import { Delisting, PrivacyPolicy } from "./pages/Links";
import Payout from "./pages/Payout";
import Profile from "./pages/Profile";
import Support from "./pages/Support";

function App() {
  const { session, setSession } = useSession();
  const { userEntities } = useAppSelector((state) => state.auth);

  useEffect(() => {
    document.title = "Tutela";
  }, []);

  useEffect(() => {
    setSession({
      isSession: userEntities.sessionId,
      isVerified: userEntities.isVerified,
    });
  }, [userEntities]);

  return (
    <div className="App">
      <Routes>
        <Route path="auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="emailconfirmation" element={<EmailConfirmation />} />
          <Route
            path="resendemailconfirmation"
            element={<ResendEmailConfirmation />}
          />
          <Route path="password/reset" element={<ResetPassword />} />
          <Route path="password/new" element={<ResetNewPassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="coin" element={<CoinLayout />}>
            <Route
              path=":coinId"
              element={<ProtectedRoute user={session} children={<Coin />} />}
            />
          </Route>
          <Route
            path="dashboard"
            element={<ProtectedRoute user={session} children={<Dashboard />} />}
          />     
          <Route
            path="reward/:coinId"
            element={<ProtectedRoute user={session} children={<Payout />} />}
          />
          <Route path="history" element={<History />}>
            <Route
              path="deposit"
              element={
                <ProtectedRoute user={session} children={<DepositHistory />} />
              }
            />
            <Route
              path="withdrawal"
              element={
                <ProtectedRoute
                  user={session}
                  children={<WithdrawalHistory />}
                />
              }
            />
          </Route>
          <Route
            path="coinlisting"
            element={<ProtectedRoute user={session} children={<CoinList />} />}
          />
          <Route
            path="support"
            element={<ProtectedRoute user={session} children={<Support />} />}
          />

          <Route
            path="profile"
            element={<ProtectedRoute user={session} children={<Profile />} />}
          />
          <Route path="privacy-policies" element={<PrivacyPolicy />} />
          <Route path="delisting" element={<Delisting />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Toast />
    </div>
  );
}

export default App;
