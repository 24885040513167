import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CloseCircleIcon } from "../../assets/icons";
import { useAppDispatch } from "../../hooks/useRedux";
import {
  useAddNewWithdrawalAddressMutation,
  useSendWithdrawalOTPMutation,
} from "../../redux/slices/depositWithdrawalSlice";
import { displayToastSuccess, displayToastWarning } from "../../redux/slices/toastSlice";

interface withdrawalNewAddressFormProps {
  address_label: string;
  new_address: string;
  address_verification: string;
}

export const WithdrawalContent = ({
  coinId,
  sessionId,
  deviceId,
  coin,
  closeModal,
}: any) => {
  const { register, handleSubmit, setError } =
    useForm<withdrawalNewAddressFormProps>();
  const buttonRef = useRef<string>("");
  const setButtonRef = (value: string) => {
    buttonRef.current = value;
  };

  const [sendWithdrawalOTP, { isLoading: sendingWithdrawalOTP }] =
    useSendWithdrawalOTPMutation();
  const [addNewWithdrawalAddress, { isLoading: addingWithdrawalAddress }] =
    useAddNewWithdrawalAddressMutation();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<withdrawalNewAddressFormProps> = ({
    address_label,
    new_address,
    address_verification,
  }) => {
    if (buttonRef.current === "otp") {
      sendWithdrawalOTP({
        coinId,
        deviceId,
        sessionId,
        withdrawalAddress: new_address,
      })
        .unwrap()
        .then((data) => {
          if (data.status === "error") {
            return dispatch(displayToastWarning({ message: data.message }));
          }
          dispatch(displayToastSuccess({ message: data.message }));
        });
    } else if (buttonRef.current === "add") {
      addNewWithdrawalAddress({
        coinId,
        deviceId,
        sessionId,
        label: address_label,
        withdrawalAddress: new_address,
        withdrawalVerificationCode: address_verification,
      })
        .unwrap()
        .then(() => {
          closeModal();
        })
    }
  };

  return (
    <>
      <div className="relative flex items-center justify-center px-1">
        <div className="flex items-center space-x-3">
          <h1 className="text-2xl font-bold tracking-thight text-white">
            Withdrawal
          </h1>
          <img
            className="w-9 h-9 rounded-full border-2 border-indigo-500 p-[1px] bg-white"
            src={coin?.image}
            alt="coin_thumb"
          />
        </div>
        <button
          onClick={closeModal}
          className="absolute right-0 text-rose-500 dark:text-rose-400"
        >
          <CloseCircleIcon className="w-10 h-10" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
        <div className="flex flex-col space-y-1 mb-4">
          <label className="text-white">Label</label>
          <input
            className={
              "appearance-none w-full block text-gray-700 border border-indigo-500 rounded leading-tight focus:outline-none focus:bg-white"
            }
            type="text"
            {...register("address_label", {})}
          />
        </div>

        <div className="flex flex-col space-y-1 mb-4">
          <label className="text-white">New Address</label>
          <div className="flex space-x-1">
            <input
              className={
                "appearance-none w-full block text-gray-700 border border-indigo-500 rounded leading-tight focus:outline-none focus:bg-white"
              }
              type="text"
              {...register("new_address", {})}
            />
            <button
              onClick={() => setButtonRef("otp")}
              className={`${
                sendingWithdrawalOTP && "opacity-75"
              } whitespace-nowrap items-center bg-transparent hover:bg-indigo-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-indigo-500 hover:border-transparent rounded`}
              disabled={sendingWithdrawalOTP}
            >
              {!sendingWithdrawalOTP ? "Send OTP" : "Processing..."}
            </button>
          </div>
        </div>

        <div className="flex flex-col space-y-1">
          <label className="text-white">Verification Code</label>
          <input
            className={
              "appearance-none w-full block text-gray-700 border border-indigo-500 rounded leading-tight focus:outline-none focus:bg-white"
            }
            type="text"
            autoComplete="off"
            {...register("address_verification", {})}
          />
        </div>

        <div className="mx-auto mt-auto">
          <button
            onClick={() => setButtonRef("add")}
            className={`${
              addingWithdrawalAddress && "opacity-75"
            } flex items-center text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center`}
            disabled={addingWithdrawalAddress}
          >
            {!addingWithdrawalAddress ? "Add Address" : "Processing..."}
          </button>
        </div>
      </form>
    </>
  );
};
