import { Helmet } from "react-helmet";

interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FC = ({}: PrivacyPolicyProps) => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - Tutela</title>
      </Helmet>

      <header className="mb-4 mt-6">
        <p>
          This privacy policy ("policy") will help you understand how Tutela
          ("us", "we", "our") uses and protects the data you provide to us when
          you visit and use Tutela ("website", "service"). We reserve the right
          to change this policy at any given time, of which you will be promptly
          updated. If you want to make sure that you are up to date with the
          latest changes, we advise you to frequently visit this page.
        </p>
      </header>
      <section className="flex flex-col space-y-2">
        <article>
          <h1 className="font-bold">What User Data We Collect</h1>
          <p>When you visit the website, we may collect the following data:</p>
          <ul className="list-disc ml-4">
            <li>Your IP address.</li>
            <li>Your email address.</li>
            <li>Your Device ID.</li>
          </ul>
        </article>

        <article>
          <h1 className="font-bold">Why We Collect Your Data</h1>
          <p>We are collecting your data for several reasons:</p>
          <ul className="list-disc ml-4">
            <li>For user login authentication.</li>
            <li>To secure access to user account.</li>
          </ul>
        </article>

        <article>
          <h1 className="font-bold">Safeguarding and Securing the Data</h1>
          <p>
            Tutela is committed to securing your data and keeping it
            confidential. Tutela has done all in its power to prevent data
            theft, unauthorized access, and disclosure by implementing the
            latest technologies and software, which help us safeguard all the
            information we collect online.
          </p>
        </article>

        <article>
          <h1 className="font-bold">
            Restricting the Collection of your Personal Data
          </h1>
          <p>
            Tutela will not lease, sell or distribute your personal information
            to any third parties.
          </p>
        </article>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
