import { Fragment, useRef, useState } from "react";
import { CloseCircleIcon, TrashIcon } from "../../assets/icons";
import { useAppDispatch } from "../../hooks/useRedux";
import {
  useDeleteWithdrawalAddressMutation,
  useSetDefaultWithdrawalAddressMutation,
} from "../../redux/slices/depositWithdrawalSlice";
import {
  displayToastSuccess,
  displayToastWarning,
} from "../../redux/slices/toastSlice";

export const ManageAddressContent = ({
  withdrawalAddressList,
  coin,
  closeModal,
  sessionId,
  deviceId,
}: any) => {
  const [deleteWithdrawalAddress] = useDeleteWithdrawalAddressMutation();
  const [setDefaultWithdrawalAddress] =
    useSetDefaultWithdrawalAddressMutation();
  const dispatch = useAppDispatch();
  const itemRef = useRef<any>();

  const setItemRef = (value: string) => (itemRef.current = value);
  const handleDeleteEvent = (value: string) => {
    setItemRef(value);

    deleteWithdrawalAddress({
      coinId: coin.id,
      addressId: itemRef.current,
      sessionId,
      deviceId,
    })
      .unwrap()
      .then((data) => {
        if (data.status === "error") {
          return dispatch(displayToastWarning({ message: data.message }));
        }
        dispatch(displayToastSuccess({ message: data.message }));
      });
  };

  const handleRadioButtonEvent = (value: string) => {
    setItemRef(value);
    setDefaultWithdrawalAddress({
      coinId: coin.id,
      addressId: itemRef.current,
      sessionId,
      deviceId,
    })
      .unwrap()
      .then((data) => {
        if (data.status === "error") {
          return dispatch(displayToastWarning({ message: data.message }));
        }
        dispatch(displayToastSuccess({ message: data.message }));
      });
  };

  const renderWithdrawalList = () => {
    return withdrawalAddressList?.map((withdrawalItem: any, index: number) => {
      return (
        <Fragment key={index}>
          <div
            id={withdrawalItem.id}
            ref={itemRef}
            className="flex flex-row items-center justify-evenly text-white space-y-2"
          >
            <p>{withdrawalItem.label}</p>
            <p>{withdrawalItem.address}</p>
            <div className="flex items-center justify-center space-x-4">
              <input
                onChange={() => handleRadioButtonEvent(withdrawalItem.id)}
                type="radio"
                checked={withdrawalItem.isDefault}
              />
              <button onClick={() => handleDeleteEvent(withdrawalItem.id)}>
                <TrashIcon className="w-6 h-6 text-rose-500" />
              </button>
            </div>
          </div>
        </Fragment>
      );
    });
  };

  return (
    <>
      <div className="relative flex items-center justify-center px-1 mb-10">
        <div className="flex items-center space-x-3">
          <h1 className="text-lg font-bold tracking-thight text-white">
            Manage {coin?.name} Address
          </h1>
        </div>
        <button
          onClick={closeModal}
          className="absolute right-0 text-rose-500 dark:text-rose-400"
        >
          <CloseCircleIcon className="w-10 h-10" />
        </button>
      </div>
      <div className="flex flex-col">{renderWithdrawalList()}</div>
    </>
  );
};
