import { useEffect, useRef, useState } from "react";
import { CheckIcon, DocumentDuplicateIcon } from "../assets/icons";
import QrCode from "./QrCode";

interface DepositAddressProps {
  wallet: any;
}

export const DepositAddress = ({ wallet }: DepositAddressProps) => {
  const [isAddressCopy, setIsAddressCopy] = useState(false);
  const depositAddressRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const timeoutForQrIcon: any = () =>
      setTimeout(() => setIsAddressCopy(false), 1500);
    if (isAddressCopy) timeoutForQrIcon();
    return () => {
      clearTimeout(timeoutForQrIcon);
    };
  }, [isAddressCopy]);

  const handleCopyAddress = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const depositAddress = depositAddressRef.current?.innerText || "";
    navigator.clipboard.writeText(depositAddress);
    return setIsAddressCopy(true);
  };

  return (
    <>
      <div className="relative flex flex-col items-center">
        <QrCode value={wallet?.deposit_address} />
        <button
          onClick={handleCopyAddress}
          className="absolute -right-5 -top-5 bg-slate-700 rounded-full p-2"
        >
          {!isAddressCopy ? (
            <DocumentDuplicateIcon className="w-7 h-7" />
          ) : (
            <CheckIcon className="w-7 h-7 text-teal-700" />
          )}
        </button>
      </div>
      <p ref={depositAddressRef} className="text-xs">
        {wallet?.deposit_address}
      </p>
    </>
  );
};
