import { Helmet } from "react-helmet";
import CoinCard from "../../components/CoinCard";
import CoinTailIndicator from "../../components/CoinTailIndicator";
import Spinner from "../../components/Spinner";
import { useSearch } from "../../context/SearchContext";
import useCoins from "../../hooks/useCoins";
import { useGetCoinCollectionQuery } from "../../redux/slices/coinCollectionSlice";
import Annoucement from "../../components/Announcement";
import { useGetAnnouncementQuery } from "../../redux/slices/bannerSlice";

const Home = () => {
  const { debouncedInput } = useSearch();
  const [coinList, fetchCoinList, hasMore] = useCoins([]);
  const {
    isError,
    isSuccess,
    data: coins,
  } = useGetCoinCollectionQuery(undefined, { refetchOnMountOrArgChange: true });
  const { isError: isGetAnnouncementError, isSuccess: isGetAnnouncementSuccess, data: announcement } =
    useGetAnnouncementQuery(undefined);

  const renderCoins = () => {
    return [...coins]
      .filter((coin: { id: string | undefined }) => {
        if (debouncedInput === "") return coin;
        if (coin.id?.includes(debouncedInput)) return coin;
      })
      .map((coin, index) => {
        return <CoinCard key={index} coin={coin} />;
      });
  };

  return (
    <div className="flex flex-col h-full">
      {isGetAnnouncementSuccess && <Annoucement announcementData={announcement}  />}
      <Helmet>
        <title>Home - Tutela</title>
      </Helmet>

      {isSuccess ? (
        <div className="w-full flex-col">
          <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 lg:grid-cols-4">
            {renderCoins()}
          </div>
          <div className="mx-auto my-10">
            {!debouncedInput && hasMore ? (
              <button
                type="button"
                className="flex items-center space-x-1 bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={fetchCoinList}
              >
                <span>Load More</span>
              </button>
            ) : (
              <CoinTailIndicator />
            )}
          </div>
        </div>
      ) : isError ? (
        ""
      ) : (
        <div className="mx-auto mt-12">
          <Spinner size="w-8 h-8" />
        </div>
      )}
    </div>
  );
};

export default Home;
