import { lazy, Suspense, memo } from "react";
import Spinner from "./Spinner";

const QrCode = memo(({ value }: any) => {
  const QRCodeCanvas = lazy(() =>
    import("qrcode.react").then((module) => ({ default: module.QRCodeCanvas }))
  );
  return !value ? (
      <Spinner size="w-12 h-12 my-auto" />
  ) : (
    <Suspense fallback={<Spinner size="w-12 h-12" />}>
      <QRCodeCanvas
        size={200}
        id="qr"
        bgColor={"#fff"}
        fgColor={"#59585f"}
        includeMargin={true}
        value={value}
      />
    </Suspense>
  );
});

export default QrCode;
