import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method });
      const list = result.data;
      if (params) return { data: list[params[0]][params[1]] };
      return { data: list };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const coinCollectionAPI = createApi({
  reducerPath: "coinCollectionAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getCoinCollection: build.query({
      query: () => ({
        url: "/stats",
        method: "GET",
      }),
    }),
    getProtectedCoin: build.query({
      query: ({ coinId, sessionId, deviceId }) => ({
        url: `/protected/coins/${coinId}`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
    }),
  }),
});

export const masternodeSharingCollectionAPI = createApi({
  tagTypes: ["MNCoinList"],
  reducerPath: "masternodeSharingCollectionAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getMasternodeSharingCollection: build.query({
      query: (userAuthPayload) => ({
        url: "/protected/sharing-service/masternode/list",
        method: "POST",
        body: userAuthPayload,
      }),
      providesTags: ["MNCoinList"],
    }),
  }),
});

export const coinTableCollectionAPI = createApi({
  reducerPath: "coinTableCollectionAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getCoinTable: build.mutation({
      query: ({isMNTrue, coinId, sessionId, deviceId}) => ({
        url: `/protected/${!isMNTrue ? "staking" : "masternodes"}/${coinId}`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
    }),
  }),
});

export const masternodeTableCollectionAPI = createApi({
  reducerPath: "masternodeTableAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getMasterTableCollection: build.query({
      query: ({ coinId, sessionId, deviceId }) => ({
        url: `/protected/masternodes/${coinId}`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
    }),
  }),
});

export const coinPriceCollectionAPI = createApi({
  reducerPath: "coinPriceCollectionAPI",
  baseQuery: axiosBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getCoinPrice: build.query({
      query: () => ({
        url: "/price",
        method: "GET",
      }),
    }),
    selectCoinPrice: build.query({
      query: (coinId) => ({
        url: "/price",
        method: "GET",
        params: coinId,
      }),
    }),
  }),
});

export const coinListNewAPI = createApi({
  reducerPath: "coinListNewCoinAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    sendCoinListing: build.mutation({
      query: (payload) => ({
        url: "/addcoin",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetCoinCollectionQuery, useGetProtectedCoinQuery } =
  coinCollectionAPI;
export const { useGetMasternodeSharingCollectionQuery } =
  masternodeSharingCollectionAPI;
export const { useGetMasterTableCollectionQuery } =
  masternodeTableCollectionAPI;
  export const { useGetCoinTableMutation } = coinTableCollectionAPI;
export const { useGetCoinPriceQuery, useSelectCoinPriceQuery } =
  coinPriceCollectionAPI;
export const { useSendCoinListingMutation } = coinListNewAPI;
