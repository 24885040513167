import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { SessionType } from "../@types/session";

interface Props {
  children?: ReactNode;
}

interface SessionContextType {
  session: SessionType;
  setSession: Dispatch<SetStateAction<SessionType>>;
}

const SessionContext = createContext<SessionContextType | null>(null);

export const SessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<SessionType>({
    isSession: false,
    isVerified: false,
  });
  const sessionProviderValue = useMemo(
    () => ({ session, setSession }),
    [session, setSession]
  );

  return (
    <SessionContext.Provider value={sessionProviderValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) throw new Error("must be in Session Context");

  return context;
};
