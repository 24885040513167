import { SetStateAction, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import { useAppSelector } from "../../hooks/useRedux";
import {
  useGetCoinCollectionQuery,
  useGetCoinPriceQuery,
  useGetMasternodeSharingCollectionQuery,
} from "../../redux/slices/coinCollectionSlice";

const Dashboard = () => {
  const { sessionId, deviceId } = useAppSelector(
    (state) => state.auth.userEntities
  );
  const [dashboardList, setDashboardList] = useState<SetStateAction<any>>();

  const { data: coinCollection, isSuccess: isCoinCollectionSuccess } =
    useGetCoinCollectionQuery(undefined);
  const { data: priceCollection, isSuccess: isPriceCollectionSuccess } =
    useGetCoinPriceQuery(undefined);
  const { data: mnCollection, isSuccess: isMNSharingCollectionSuccess } =
    useGetMasternodeSharingCollectionQuery({
      sessionId,
      deviceId,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isCoinCollectionSuccess &&
      isPriceCollectionSuccess &&
      isMNSharingCollectionSuccess
    ) {
      setDashboardList(() => {
        return coinCollection.reduce((collection: any, item: any) => {
          const newItem = {
            id: item.id,
            name: item.name,
            image: item.image,
            symbol: item.symbol,
            price: priceCollection?.[`${item.id}`]?.[`usd`]?.price,
            change_24h: priceCollection?.[`${item.id}`]?.[`usd`]?.change_24h,
            balance: mnCollection?.[`${item.id}`]?.balance,
            worth_balance:
              mnCollection?.[`${item.id}`]?.balance *
              priceCollection?.[`${item.id}`]?.[`usd`]?.price,
          };
          return [...collection, newItem];
        }, []);
      });
    }
  }, [
    isCoinCollectionSuccess,
    isPriceCollectionSuccess,
    isMNSharingCollectionSuccess,
  ]);

  const dashboardTableColumns = [
    {
      Header: "Coin",
      accessor: "name",
      Cell: ({ row: item }: any) => (
        <button
          className="flex items-center space-x-2"
          onClick={() => handleToCoinReward(item.original.id)}
        >
          <img
            src={item.original.image}
            alt="logo"
            className="w-6 h-6 bg-white p-[2px] rounded-full"
          />
          <p>{`${item.original.name}`}</p>
          <p className="uppercase">{`(${item.original.symbol})`}</p>
        </button>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ row: item }: any) => `$ ${item.original.price}`,
    },
    {
      Header: "Change 24",
      accessor: "change_24h",
      Cell: ({ row: item }: any) => (
        <>
          {item.original.change_24h >= 0 ? (
            <p>{`+${item.original.change_24h}%`}</p>
          ) : (
            <p>{`${item.original.change_24h}%`}</p>
          )}
        </>
      ),
    },
    {
      Header: "Balance",
      accessor: "balance",
      Cell: ({ row: item }: any) => (
        <div className="flex items-center space-x-1">
          <p>{item.original.balance}</p>
          <p className="uppercase">{item.original.symbol}</p>
        </div>
      ),
    },
    {
      Header: "Worth Balance",
      accessor: "worth_balance",
      Cell: ({ row: item }: any) =>
        `$ ${item.original.worth_balance.toFixed(2)}`,
    },
  ];

  const handleToCoinReward = (value: string) => {
    navigate(`/reward/${value}`);
  };

  return (
    <div className="flex-1 w-full flex flex-col mx-auto">
      <Helmet>
        <title>Dashboard - Tutela</title>
      </Helmet>

      <div className="w-full flex flex-col">
        {dashboardList ? (
          <Table
            listOfItems={dashboardList}
            tableColumns={dashboardTableColumns}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Dashboard;
