import { SearchInputProps } from "../@types/search";
import { SearchIcon } from "../assets/icons";

const Searchbar = ({
  searchInput,
  handleSearchInputEvent,
  searchbarSize,
}: SearchInputProps) => {
  return (
    <form className={searchbarSize}>
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
        </div>
        <input
          type="search"
          id="default-search"
          value={searchInput}
          onChange={handleSearchInputEvent}
          className="block lg:w-2/3 lg:p-4 pl-10 lg:pl-10 text-sm text-white border border-gray-600 rounded-xl bg-slate-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          placeholder="Search Coin..."
          autoComplete="off"
          required
        />
      </div>
    </form>
  );
};

export default Searchbar;
