const ActiveIcon = ({ isActiveIcon, children }: any) => {
  return isActiveIcon ? (
    <div className="flex flex-col items-center space-y-2 text-indigo-500">
      {children}
    </div>
  ) : (
    <div className="flex flex-col items-center space-y-2">{children}</div>
  );
};

export default ActiveIcon;
