import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { heroSectionProps } from "../@types/herosection";

const HeroSection = ({
  children,
  className,
}: heroSectionProps) => {
  const { pathname } = useLocation();
  const lastUrlRef = useRef<string | undefined>();
  lastUrlRef.current = pathname.split("/").pop();

  const renderHeroSection = () => {
    if (lastUrlRef.current === "") {
      <></>
    }

    return <>{children}</>;
  };

  return <div className={className}>{renderHeroSection()}</div>;
};

export default HeroSection;
