const CoinTailIndicator = () => {
  return (
    <div className="mx-auto my-24">
      <div className="flex flex-col items-center text-gray-500">
        <h1>No More Coin Found</h1>
      </div>
    </div>
  );
};

export default CoinTailIndicator;
