import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { resetNewPassword } from "../../redux/slices/authSlice";
import { displayToastSuccess } from "../../redux/slices/toastSlice";

interface IResetNewPassword {
  newPassword: string;
}

const ResetNewPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<IResetNewPassword>();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IResetNewPassword> = ({ newPassword }) => {
      dispatch(resetNewPassword({ newPassword }))
        .unwrap()
        .then((data) => {
          dispatch(displayToastSuccess({ message: data.message }));
          navigate("/auth/login");
        })
        .catch((rejectValueOrSerializedError) => {
          setError("newPassword", {
            type: "custom",
            message: rejectValueOrSerializedError.message
              ? rejectValueOrSerializedError.message
              : rejectValueOrSerializedError,
          });
        });

  };

  return (
    <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
      <form
        className="flex flex-col space-y-4 rounded-lg p-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <h1 className="font-bold text-2xl">Password Reset</h1>
          <p className="text-gray-500 dark:text-white">
            Please enter your new password.
          </p>
        </div>
        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            New Password:
          </label>

          <input
            type="password"
            placeholder="************"
            className={`appearance-none block w-full bg-gray-700 text-white border ${
              errors.newPassword
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            {...register("newPassword", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password too short",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "#testUser0ne",
            })}
          />

          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.newPassword && (
              <span className="flex items-center">
                {errors?.newPassword.message}
              </span>
            )}
          </span>
        </div>
        <div className="mx-auto">
          <button className="flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetNewPassword;
