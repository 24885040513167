import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOnRecIcon,
  ArrowRightOnRecIcon,
  FlagIcon,
  RectangleStackIcon,
  RectangleGroupIcon,
  HomeIcon,
  BookOpenIcon,
  SuitcaseIcon,
  SquareStackIcon,
} from "../assets/icons";
import { useSession } from "../context/SessionContext";
import { useAppDispatch } from "../hooks/useRedux";
import { logout } from "../redux/slices/authSlice";
import ActiveIcon from "./ActiveIcon";
import NavigationLink from "./NavigationLink";

const Sidebar = () => {
  const { session, setSession } = useSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogEvent = () => {
    if (!session.isSession) {
      return navigate("/auth/login");
    }

    setSession({ ...session, isSession: false });
    dispatch(logout());
    navigate("/");
  };

  const renderSidebar = () => {
    return (
      <div className="fixed top-0 left-0 w-24 h-screen flex flex-col items-center text-light bg-stone-800">
        <div className="h-24 flex items-center">
          <img src="/500x500.png" className="w-16" alt="tutela" />
        </div>
        <div className="flex flex-col h-3/5 font-light space-y-6 py-12">
          <NavigationLink
            to="/"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <HomeIcon className="w-6 h-6" />
                    <span className="text-xs">Home</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <HomeIcon className="w-6 h-6" />
                    <span className="text-xs">Home</span>
                  </>
                }
              />
            }
          />
          {session.isSession && (
            <NavigationLink
              to="/dashboard"
              activeIcon={
                <ActiveIcon
                  isActiveIcon={true}
                  children={
                    <>
                      <RectangleGroupIcon className="w-6 h-6" />
                      <span className="text-xs">Dashboard</span>
                    </>
                  }
                />
              }
              inactiveIcon={
                <ActiveIcon
                  isActiveIcon={false}
                  children={
                    <>
                      <RectangleGroupIcon className="w-6 h-6" />
                      <span className="text-xs">Dashboard</span>
                    </>
                  }
                />
              }
            />
          )}

          {session.isSession && (
            <NavigationLink
              to="/history"
              activeIcon={
                <ActiveIcon
                  isActiveIcon={true}
                  children={
                    <>
                      <BookOpenIcon className="w-6 h-6" />
                      <span className="text-xs">History</span>
                    </>
                  }
                />
              }
              inactiveIcon={
                <ActiveIcon
                  isActiveIcon={false}
                  children={
                    <>
                      <BookOpenIcon className="w-6 h-6" />
                      <span className="text-xs">History</span>
                    </>
                  }
                />
              }
            />
          )}

          <NavigationLink
            to="/coinlisting"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <RectangleStackIcon className="w-6 h-6" />
                    <span className="text-xs">List New Coin</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <RectangleStackIcon className="w-6 h-6" />
                    <span className="text-xs">List New Coin</span>
                  </>
                }
              />
            }
          />
          <NavigationLink
            to="/support"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <FlagIcon className="w-6 h-6" />
                    <span className="text-xs">Support</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <FlagIcon className="w-6 h-6" />
                    <span className="text-xs">Support</span>
                  </>
                }
              />
            }
          />
        </div>
        <div className="my-auto border-t border-stone-700 pt-4">
          <button
            type="button"
            className="flex flex-col items-center space-x-1 text-xs"
            onClick={handleLogEvent}
          >
            {session.isSession ? (
              <ArrowLeftOnRecIcon className="w-6 h-6" />
            ) : (
              <ArrowRightOnRecIcon className="w-6 h-6" />
            )}
            <span>{session.isSession ? "Log Out" : "Log In"}</span>
          </button>
        </div>
      </div>
    );
  };

  return <>{renderSidebar()}</>;
};

export default Sidebar;
