import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import {
  confirmEmail,
  getIpAddress,
  loginUser,
  processResetNewPassword,
  processResetPassword,
  processResetPasswordCode,
  processUpdatePassword,
  registerUser,
  resendConfirmEmail,
} from "../../../api/Auth";

interface IAuthSlice {
  status: string;
  userEntities: any;
  message: string | SerializedError;
}

interface ILoginSignUpProps {
  email: string;
  password: string;
}

interface IEmailConfirmProp {
  emailConfirm: string;
}

interface IResendEmailConfirmProp {
  userEmail: string;
}

interface IPasswordResetProp {
  emailRecovery: string;
}

interface IPasswordResetCodeProp {
  resetCode: string;
}

interface INewPasswordResetProp {
  newPassword: string;
}

interface IPasswordUpdateProp {
  currentPassword: string;
  newPassword: string;
}

const initialState: IAuthSlice = {
  status: "idle",
  userEntities: {},
  message: "",
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: ILoginSignUpProps, { rejectWithValue }) => {
    try {
      const deviceIdRes = await getIpAddress();
      const deviceId = deviceIdRes.data;
      const response = await loginUser({ email, password, deviceId });
      if (response.data.status === "success") {
        let data = { ...response.data, deviceId: deviceId, email: email };

        return data;
      } else {
        throw response.data;
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async ({ email, password }: ILoginSignUpProps, { rejectWithValue }) => {
    try {
      const deviceIdRes = await getIpAddress();
      const deviceId = deviceIdRes.data;
      const response = await registerUser({ email, password, deviceId });

      if (response.data.status === "success") {
        let data = { ...response.data, email };
        return data;
      } else {
        throw response.data;
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verification",
  async (
    { emailConfirm }: IEmailConfirmProp,
    { rejectWithValue, getState }
  ) => {
    try {
      const deviceIdRes = await getIpAddress();
      const deviceId = deviceIdRes.data;

      let { auth } = getState() as any;
      const userId = auth.userEntities.userId;
      const email = auth.userEntities.email;
      const response = await confirmEmail({
        userId,
        email,
        emailConfirm,
        deviceId,
      });

      if (response.data.status === "success") {
        return { ...response.data, deviceId: deviceId };
      } else {
        throw response.data;
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const resendVerifyEmail = createAsyncThunk(
  "auth/resendverificationemail",
  async ({userEmail} : IResendEmailConfirmProp, { rejectWithValue, getState }) => {
    try {
      let { auth } = getState() as any;
      const response = await resendConfirmEmail({
        email: userEmail,
        userId: auth.userEntities.userId
      });

      if (response.data.status === "success") {
        const resendVerifyEmailPayload = { ...response.data, email: userEmail };
        return resendVerifyEmailPayload;
      } else {
        throw response.data;
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);


export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ emailRecovery }: IPasswordResetProp, { rejectWithValue }) => {
    try {
      const response = await processResetPassword({ email: emailRecovery });

      if (response.data.status === "error") throw response.data;
      return { ...response.data, email: emailRecovery };
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.message);
    }
  }
);

export const resetPasswordCode = createAsyncThunk(
  "auth/resetPasswordCode",
  async (
    { resetCode }: IPasswordResetCodeProp,
    { rejectWithValue, getState }
  ) => {
    try {
      const { auth } = (await getState()) as any;
      const { email } = await auth.userEntities;
      const response = await processResetPasswordCode({
        email,
        code: resetCode,
      });

      if (response.data.status === "error") throw response.data;
      return { ...response.data, email, code: resetCode };
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.message);
    }
  }
);

export const resetNewPassword = createAsyncThunk(
  "auth/resetNewPassword",
  async (
    { newPassword }: INewPasswordResetProp,
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = (await getState()) as any;
      const { email, code } = await auth.userEntities;
      const response = await processResetNewPassword({
        email,
        code,
        newPassword,
      });

      if (response.data.status === "error") throw response.data;
      return response.data;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (
    { currentPassword, newPassword }: IPasswordUpdateProp,
    { rejectWithValue, getState }
  ) => {
    try {
      const { auth } = getState() as any;
      const { sessionId, deviceId } = auth.userEntities;

      const response = await processUpdatePassword({
        currentPassword,
        newPassword,
        sessionId,
        deviceId,
      });
      if (response.data.status === "error") {
        throw response.data;
      }
      return response.data;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(signup.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.userEntities = {
          ...state.userEntities,
          sessionId: "",
          userId: action.payload.userId,
          deviceId: action.payload.deviceId,
          email: action.payload.email,
        };
        state.message = action.payload;
      })
      .addCase(signup.rejected, (state, action: PayloadAction<any>) => {
        state.status = "error";
        if (action.payload) state.message = action.payload;
        else state.message = action.payload;
      });

    builder
      .addCase(login.pending, (state) => {
        state.status = "pending";
        state.message = "";
        console.log(state.status);
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.message = action.payload.message;
        state.userEntities = {
          ...state.userEntities,
          userId: action.payload.userId,
          sessionId: action.payload.sessionId,
          isVerified: action.payload.isVerified,
          deviceId: action.payload.deviceId,
          email: action.payload.email,
        };
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.status = "error";
        state.message = action.payload;
      });

    builder
      .addCase(verifyEmail.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(verifyEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.message = action.payload.message;
        state.userEntities = {
          userId: state.userEntities.userId,
          sessionId: action.payload.sessionId,
        };
      })
      .addCase(verifyEmail.rejected, (state, action: PayloadAction<any>) => {
        state.status = "error";
        if (action.payload) state.message = action.payload;
        else state.message = action.payload;
      });

      builder
      .addCase(resendVerifyEmail.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(resendVerifyEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.message = action.payload.message;
        state.userEntities = {
          ...state.userEntities,
          email: action.payload.email,
        };
      })
      .addCase(resendVerifyEmail.rejected, (state, action: { error: any, payload: any}) => {
        state.status = "error";
        if (action.payload) state.message = action.payload;
        else state.message = action.error.message;
      }); 

    builder
      .addCase(resetPassword.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(resetPassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.message = action.payload.message;
        state.userEntities = {
          userId: "",
          sessionId: "",
          deviceId: "",
          email: action.payload.email,
          isVerified: "",
        };
      })
      .addCase(
        resetPassword.rejected,
        (state, action: { error: any; payload: any }) => {
          state.status = "error";
          if (action.payload) state.message = action.payload;
          else state.message = action.error.message;
        }
      );

    builder
      .addCase(resetPasswordCode.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(
        resetPasswordCode.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "success";
          state.message = action.payload.message;
          state.userEntities = {
            userId: "",
            sessionId: "",
            deviceId: "",
            email: action.payload.email,
            code: action.payload.code,
            isVerified: "",
          };
        }
      )
      .addCase(
        resetPasswordCode.rejected,
        (state, action: { error: any; payload: any }) => {
          state.status = "error";
          if (action.payload) state.message = action.payload;
          else state.message = action.error.message;
        }
      );

    builder
      .addCase(resetNewPassword.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(
        resetNewPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "success";
          state.message = action.payload.message;
          state.userEntities = {
            userId: "",
            sessionId: "",
            deviceId: "",
            isVerified: "",
          };
        }
      )
      .addCase(
        resetNewPassword.rejected,
        (state, action: { error: any; payload: any }) => {
          state.status = "error";
          if (action.payload) state.message = action.payload;
          else state.message = action.error.message;
        }
      );

    builder
      .addCase(updatePassword.pending, (state) => {
        state.status = "pending";
        state.message = "";
      })
      .addCase(
        updatePassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload);
          state.status = "success";
          state.message = action.payload.message;
        }
      )
      .addCase(
        updatePassword.rejected,
        (state, action: { error: any; payload: any }) => {
          state.status = "error";
          if (action.payload) state.message = action.payload;
          else state.message = action.error.message;
        }
      );
  },
  reducers: {
    logout: (state) => {
      state.status = "idle";
      state.userEntities = {};
      state.message = "";
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
