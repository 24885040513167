import axios from "axios";
import {
  IConfirmEmailProps,
  ILoginSignUpProps,
  IResendConfirmCodeProps,
  IResetNewPasswordProps,
  IResetPasswordCodeProps,
  IResetPasswordProps,
  IUpdatePasswordProps,
} from "../@types/auth";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const getIpAddress = (): Promise<any> => {
  return axios.get("https://ip.tutela.io/");
};

export const registerUser = ({
  email,
  password,
  deviceId,
}: ILoginSignUpProps) => {
  return axios.post(`${API_URL}/register`, {
    email,
    password,
    deviceId,
  });
};

export const loginUser = ({ email, password, deviceId }: ILoginSignUpProps) => {
  return axios.post(`${API_URL}/login`, {
    email,
    password,
    deviceId,
  });
};

export const confirmEmail = ({
  userId,
  email,
  emailConfirm,
  deviceId,
}: IConfirmEmailProps) => {
  return axios.post(`${API_URL}/verification`, {
    verification_type: "registration",
    email,
    userId,
    code: emailConfirm,
    deviceId,
  });
};

export const resendConfirmEmail = ({email, userId}: IResendConfirmCodeProps) => {
  return axios.post(`${API_URL}/verification`, {
    verification_type: "resend_registration_code",
    email,
    userId,
  });
};

export const processResetPassword = ({ email }: IResetPasswordProps) => {
  return axios.post(`${API_URL}/recover`, {
    requestType: "reset",
    email,
  });
};

export const processResetPasswordCode = ({
  email,
  code,
}: IResetPasswordCodeProps) => {
  return axios.post(`${API_URL}/verification`, {
    verification_type: "password_recovery",
    email,
    code,
  });
};

export const processResetNewPassword = ({
  email,
  code,
  newPassword,
}: IResetNewPasswordProps) => {
  return axios.post(`${API_URL}/recover`, {
    requestType: "new_password",
    email,
    code,
    newPassword
  })
};

export const processUpdatePassword = ({
  currentPassword,
  newPassword,
  sessionId,
  deviceId,
}: IUpdatePasswordProps) => {
  return axios.post(`${API_URL}/protected/change-password`, {
    sessionId,
    deviceId,
    oldpassword: currentPassword,
    newpassword: newPassword,
  });
};
