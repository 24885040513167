const ShareDescription = () => {
  return (
    <>
      <div className="flex overflow-x-scroll scrollbar-hide space-x-4 mt-4 mb-10 text-sm font-light">
        <ul className="w-72 flex-grow-0 flex-shrink-0 bg-gray-800 py-4 px-4 rounded-lg space-y-2 list-interpunct">
          <li>
            <p>
              You start earning rewards as soon as you make a deposit (If there
              is at least one masternode running) or staking service is enabled.
            </p>
          </li>
          <li>
            <p>
              Masternode and staking rewards are automatically reinvested to your deposit
              amount.
            </p>
          </li>
          <li>
            <p>
              Your deposit will show in the system once it reaches 25 confirmations.
            </p>
          </li>
          <li>
            <p>
              Do not mine directly to your deposit address, we are not
              responsible any loss of coins while doing so.
            </p>
          </li>
        </ul>

        <ul className="w-72 flex-grow-0 flex-shrink-0 bg-gray-800 py-4 px-4 rounded-lg space-y-2 list-interpunct">
          <li>
            <p>
              We reserve the right to send the coins invested in shared
              masternodes back to your withdrawal address without any prior
              notice, because of this make sure that your withdrawal address is
              always up to date and is preferably address from your local
              wallet.
            </p>
          </li>
          <li>
            <p>
              Deposits under minimal deposit amount are lost.
            </p>
          </li>
        </ul>

        <ul className="w-72 flex-grow-0 flex-shrink-0 bg-gray-800 py-4 px-4 rounded-lg space-y-2 list-interpunct">
          <li>
            <p>
              Withdrawals are mostly instant, sometimes when there are multiple
              withdrawals in queue it can take from few minutes to few hours,
              depending on block time and withdrawals in queue.
            </p>
          </li>
          <li>
            <p>
              We take fees from masternode and staking rewards, which is used for server
              hosting fees and masternode management.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ShareDescription;
