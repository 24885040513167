import Searchbar from "./Searchbar";

import {
  ArrowLeftOnRecIcon,
  ArrowRightOnRecIcon,
  UserIcon,
} from "../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSearch } from "../context/SearchContext";
import { useSession } from "../context/SessionContext";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { logout } from "../redux/slices/authSlice";
import { useTopbarTitle } from "../context/TopbarTitleContext";

const Topbar = ({ dimensionWidth }: any) => {
  const { searchInput, handleSearchInputEvent } = useSearch();
  const { topbarTitle } = useTopbarTitle();
  const { pathname } = useLocation();
  const { session, setSession } = useSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { email } = useAppSelector((state) => state.auth.userEntities);

  const handleLogEvent = () => {
    if (!session.isSession) {
      return navigate("/auth/login");
    }

    setSession({ ...session, isSession: false });
    dispatch(logout());
    navigate("/");
  };

  const renderTopbar = () => {
    return (
      <div className="sticky z-10 top-0 w-full h-24 bg-stone-900 flex flex-row items-center">
        {pathname?.split("/").pop() === "" ? (
          <Searchbar
            searchInput={searchInput}
            handleSearchInputEvent={handleSearchInputEvent}
            searchbarSize="w-1/2"
          />
        ) : (
          <h1 className="text-2xl font-bold tracking-wide capitalize">
            {pathname.split("/").length > 2
              ? topbarTitle
              : pathname.split("/").pop()}
          </h1>
        )}
        <div className="ml-auto flex items-center space-x-4">
          {dimensionWidth <= 1024 ? "" : <p>{email ? email : ""}</p>}
          <Link to="/profile">
            <UserIcon className="w-10 h-10" />
          </Link>
          {dimensionWidth <= 1024 ? (
            <button
              type="button"
              className="flex flex-col items-center text-xs"
              onClick={handleLogEvent}
            >
              {session.isSession ? (
                <ArrowLeftOnRecIcon className="w-6 h-6" />
              ) : (
                <ArrowRightOnRecIcon className="w-6 h-6" />
              )}
              <span>{session.isSession ? "Log Out" : "Log In"}</span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return <>{renderTopbar()}</>;
};

export default Topbar;
