import {
  ChangeEvent,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface Props {
  children?: ReactNode;
}

interface SearchContextProps {
  searchInput: string;
  debouncedInput: string;
  handleSearchInputEvent: any;
}

const SearchContext = createContext<SearchContextProps | null>(null);

export const SearchProvider = ({ children }: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const [debouncedInput, setDebouncedInput] = useState("");

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedInput(searchInput.toLowerCase());
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchInput]);

  const handleSearchInputEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const searchText = event.target.value;

      setSearchInput(searchText);
    },
    [searchInput]
  );

  const searchContextValue = () => ({
    searchInput,
    debouncedInput,
    handleSearchInputEvent,
  });

  return (
    <SearchContext.Provider value={searchContextValue()}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);

  if (!context) throw new Error("must be in context");
  return context;
};
