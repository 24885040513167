import { useEffect, useState } from "react";

const useDimension = () => {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleSetDimensionEvent = () => {
      return setDimension([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleSetDimensionEvent);

    return () => window.removeEventListener("resize", handleSetDimensionEvent);
  }, []);

  return [dimension];
};

export default useDimension;
