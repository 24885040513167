import { useEffect, useState } from "react";

interface IAnnouncementDataProps {
  announcementData: any;
}

const Annoucement = ({ announcementData }: IAnnouncementDataProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % announcementData.length);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {!announcementData || !announcementData.length ? (
        ""
      ) : (
        <div
          id="banner"
          className="min-h-20 z-50 mb-8 py-3 px-4 border border-b sm:items-center border-gray-700 lg:py-4 bg-gray-800"
        >
          <p>{announcementData[count]?.title}</p>
          <p className="text-sm font-light text-gray-400">
            {announcementData[count]?.body}
          </p>
        </div>
      )}
    </>
  );
};

export default Annoucement;
