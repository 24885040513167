import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const userWalletAPI = createApi({
  reducerPath: "userWalletAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    selectUserWallet: build.query({
      query: (args) => {
        const { coinId, sessionId, deviceId } = args;
        return {
          url: `/protected/wallet/${coinId}`,
          method: "POST",
          body: { sessionId: sessionId, deviceId: deviceId },
        };
      },
    }),
  }),
});

export const { useSelectUserWalletQuery } = userWalletAPI;
