import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { SessionType } from "../@types/session";

interface Props {
  user: SessionType;
  children?: ReactNode;
}

const ProtectedRoute = ({ user, children }: Props) => {
  if (!user.isSession || !user.isVerified) {
    return <Navigate to="/auth/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
