import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { IFormInputs } from "../../@types/forminputs";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { login } from "../../redux/slices/authSlice";
import { displayToastSuccess, displayToastWarning } from "../../redux/slices/toastSlice";

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>();

  const { status } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IFormInputs> = ({ email, password }) => {
    dispatch(login({ email, password }))
      .unwrap()
      .then(({ isVerified }) => {
        if (!isVerified) {
          navigate("/auth/resendemailconfirmation");
          dispatch(displayToastWarning({ message: "Your email is not verified" }));
          return;
        }
        navigate("/");
        dispatch(displayToastSuccess({ message: "You're logged in" }));
      })
      .catch((errorValue) => {
        setError("email", {
          type: "custom",
          message: errorValue.message,
        });
      });
  };

  return (
    <div className="flex-1 flex flex-col items-center justify-center space-y-2">
      <form
        className="flex flex-col space-y-4 sm:border border-slate-500 rounded-lg p-4 w-full sm:w-2/4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mx-auto sm:mx-0">
          <h1 className="font-bold text-2xl">Login</h1>
        </div>
        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Email:
          </label>
          <input
            type="text"
            placeholder="pauljohn29@mail.com"
            className={`appearance-none block w-full bg-gray-700 text-white border ${
              errors.email
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            {...register("email", {
              required: "You must provide email",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "userOne@email.com",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.email && errors.email.message}
          </span>
        </div>
        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Password:
          </label>
          <input
            type="password"
            placeholder="************"
            className={`appearance-none block w-full bg-gray-700 text-white border ${
              errors.password
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password too short",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "#testUser0ne",
            })}
          />
          <div className="flex items-center justify-between text-xs">
            <Link to="/auth/password/reset">
              <span className="underline hover:text-indigo-500">
                Reset Password
              </span>
            </Link>
            <span className="text-rose-500">
              {errors.password && errors.password.message}
            </span>
          </div>
        </div>
        <div className="mx-auto sm:mx-0 sm:ml-auto">
          <button
            className={`flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${
              status === "pending" && "disabled:opacity-50"
            }`}
            disabled={status === "pending" && true}
          >
            {status === "pending" ? (
              <>
                <Spinner size="w-4 h-4" />
                <span>Loading...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      <div>
        <Link to="/auth/register">
          <p className="font-light text-sm underline">Create new account</p>
        </Link>
      </div>
    </div>
  );
};

export default Login;
