import { useForm } from "react-hook-form";
import { IUpdatePasswordFormInputs } from "../../@types/forminputs";
import { useAppDispatch } from "../../hooks/useRedux";
import { logout, updatePassword } from "../../redux/slices/authSlice";
import {
  displayToastSuccess,
  displayToastWarning,
} from "../../redux/slices/toastSlice";

const Profile = () => {
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm<IUpdatePasswordFormInputs>();

  const dispatch = useAppDispatch();

  const onSubmit = ({
    currentpassword,
    newpassword,
    confirm,
  }: IUpdatePasswordFormInputs) => {
    dispatch(
      updatePassword({
        currentPassword: currentpassword,
        newPassword: newpassword,
      })
    )
      .unwrap()
      .then((data) => {
        dispatch(displayToastSuccess({ message: data.message }));
      })
      .then(() => {
        dispatch(logout());
      })
      .catch((rejectValueOrSerializedError) => {
        if (rejectValueOrSerializedError.message) {
          dispatch(
            displayToastWarning({
              message:
                rejectValueOrSerializedError.message &&
                rejectValueOrSerializedError.message,
            })
          );
        }
        reset();
      });
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="mb-4">
        <h1 className="text-2xl lg:text-3xl font-black dark:text-white">Update Password</h1>
        <p className="text-gray-500 dark:text-white">
          Please fill the form below
        </p>
      </div>
      <div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <label className="text-sm mb-2 dark:text-white">
              Current Password
            </label>
            <input
              {...register("currentpassword", {
                required: "Field must not be empty",
              })}
              type="password"
              className={`appearance-none lg:w-1/3 block bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
            />
            <div className="h-5 flex justify-end">{/* error */}</div>
          </div>

          <div className="flex flex-col">
            <label className="text-sm mb-2 dark:text-white">New Password</label>
            <div className="relative flex items-center">
              <input
                type={"password"}
                className={`appearance-none lg:w-1/3 block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                {...register("newpassword", {
                  required: "Field must not be empty",
                })}
              />
            </div>
            <div className="h-5 flex justify-end">
              <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                {/* error */}
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-sm mb-2 dark:text-white">
              Confirm Password
            </label>
            <input
              type="password"
              className={`appearance-none lg:w-1/3 block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
              {...register("confirm", {
                required: "Field must not be empty",
                validate: {
                  newPasswordConfirmValidate: (value) =>
                    value === getValues().newpassword || "Password not match",
                },
              })}
            />
            <div className="h-5 flex justify-end">
              <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                {/* error */}
              </span>
            </div>
          </div>
          <div className="mx-auto lg:mx-0 my-6">
            <button
              className={`flex items-center bg-transparent hover:bg-indigo-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}
            >
              <span className="tracking-wide font-bold">Update Password</span>
            </button>
          </div>
        </form>
        <div className="border border-b-0 border-gray-700"></div>
        
      </div>
    </div>
  );
};

export default Profile;
