import { SetStateAction, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Table from "../../components/Table";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";
import { useGetPayoutHistoryByCoinQuery } from "../../redux/slices/historyCollectionSlice";
import { useTopbarTitle } from "../../context/TopbarTitleContext";

const Payout: React.FC = () => {
  const [totalReward, setTotalReward] = useState<number>(0);
  const [rewardList, setRewardList] = useState<SetStateAction<any>>();

  const { coinId } = useParams();
  const { sessionId, deviceId } = useAppSelector(
    (state) => state.auth.userEntities
  );
  const { setTopbarTitle } = useTopbarTitle();

  const {
    data: coinRewardData,
    isLoading: isCoinRewardDataLoading,
    isSuccess: isCoinRewardDataSuccess,
  } = useGetPayoutHistoryByCoinQuery({ coinId, sessionId, deviceId });

  useEffect(() => {
    setTopbarTitle("Reward History " + coinId);
  }, []);

  useEffect(() => {
    if (isCoinRewardDataSuccess) {
      (async () => {
        return await coinRewardData.reduce(
          (
            collection: object[],
            item: {
              amount: number;
              date_time: string;
              rewardType: string;
              share: string;
            }
          ): object[] => {
            const newItem = {
              amount: item.amount,
              date_time: item.date_time,
              rewardType: item.rewardType,
              share: item.share,
            };

            return [...collection, newItem];
          },
          []
        );
      })()
        .then((res) => {
          setRewardList(res);
          return res;
        })
        .then((res) => {
          setTotalReward(() =>
            res.reduce(
              (
                total: number,
                item: {
                  amount: number;
                  date_time: string;
                  rewardType: string;
                  share: string;
                }
              ): number => {
                return total + item.amount;
              },
              0
            )
          );
        });
    }
  }, [isCoinRewardDataSuccess]);

  const rewardTableColumns = [
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row: item }: any) => `${item.original.amount}`,
    },
    {
      Header: "Date",
      accessor: "date_time",
      Cell: ({ row: item }: any) => `${item.original.date_time}`,
    },
    {
      Header: "Reward Type",
      accessor: "rewardType",
      Cell: ({ row: item }: any) => `${item.original.rewardType}`,
    },
    {
      Header: "Share",
      accessor: "share",
      Cell: ({ row: item }: any) => `${item.original.share}`,
    },
  ];

  return (
    <div className="flex-1 w-full flex flex-col mx-auto">
      <Helmet>
        <title>Dashboard - Tutela</title>
      </Helmet>

      <h1 className="text-2xl sm:text-3xl font-bold">{`Total Reward: ${
        isCoinRewardDataLoading
          ? "Loading..."
          : totalReward
          ? totalReward.toFixed(4)
          : "No Reward Data"
      }`}</h1>

      <div className="w-full flex flex-col">
        {rewardList ? (
          <Table listOfItems={rewardList} tableColumns={rewardTableColumns} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Payout;
