import { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { resetPassword, resetPasswordCode } from "../../redux/slices/authSlice";
import { displayToastSuccess } from "../../redux/slices/toastSlice";

interface IResetPassword {
  resetCode: string;
  emailRecovery: string;
}

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<IResetPassword>();

  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const buttonRef = useRef<string>();
  const setButtonRef = (value: string) => (buttonRef.current = value);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IResetPassword> = ({
    emailRecovery,
    resetCode,
  }) => {
    if (buttonRef.current === "emailSubmit") {
      dispatch(resetPassword({ emailRecovery }))
        .unwrap()
        .then((data) => {
          dispatch(displayToastSuccess({ message: data.message }));
          setIsEmailSubmit(true);
          reset();
        })
        .catch((rejectValueOrSerializedError) => {
          setError("emailRecovery", {
            type: "custom",
            message: rejectValueOrSerializedError.message
              ? rejectValueOrSerializedError.message
              : rejectValueOrSerializedError,
          });
        });
    } else if (buttonRef.current === "codeSubmit") {
      dispatch(resetPasswordCode({ resetCode }))
        .unwrap()
        .then(() => {
          navigate("/auth/password/new")
        })
        .catch((rejectValueOrSerializedError) => {
          // why is error not showing ?
          setError("resetCode", {
            type: "custom",
            message: rejectValueOrSerializedError.message
              ? rejectValueOrSerializedError.message
              : rejectValueOrSerializedError,
          });
          reset();
        });
    }
  };

  const renderResetPassword = () => {
    if (!isEmailSubmit) {
      return (
        <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
          <form
            className="flex flex-col space-y-4 rounded-lg p-4 w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <h1 className="font-bold text-2xl">Password Reset</h1>
              <p className="text-gray-500 dark:text-white">
                Please enter your email below, we will send a confirmation code
                to reset your password.
              </p>
            </div>
            <div className="relative flex flex-col">
              <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
                Email Address:
              </label>
              <input
                type="text"
                placeholder="youremail@address.com"
                className={`appearance-none block w-full bg-gray-700 text-white border ${
                  errors.emailRecovery
                    ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                    : "border-indigo-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
                {...register("emailRecovery", {
                  required: "You must provide email",
                  value:
                    process.env.REACT_APP_ENV === "staging" ||
                    process.env.REACT_APP_ENV === "production"
                      ? ""
                      : "XGDPIHCBKV",
                })}
              />
              <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
                {errors.emailRecovery && (
                  <span className="flex items-center">
                    {errors?.emailRecovery.message}
                  </span>
                )}
              </span>
            </div>
            <div className="mx-auto">
              <button
                onClick={() => setButtonRef("emailSubmit")}
                className="flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              >
                Send Code
              </button>
            </div>
          </form>
        </div>
      );
    } else {
      return (
        <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
          <form
            className="flex flex-col space-y-4 rounded-lg p-4 w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <h1 className="font-bold text-2xl">Reset Confirmation</h1>
              <p className="text-gray-500 dark:text-white">
                Please enter your confirmation code from your email to reset
                your password.
              </p>
            </div>
            <div className="relative flex flex-col">
              <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
                Confirmation Code:
              </label>
              <input
                type="text"
                className={`rounded-md h-12 font-bold text-white bg-gray-700 border-indigo-500 ${
                  errors.resetCode
                    && "border-rose-500 focus:ring-0 focus:border-rose-500 dark:bg-rose-300"
                }`}
                {...register("resetCode", {
                  required: "Field must not empty",
                })}
              />
              <span className="text-xs text-rose-500 absolute -bottom-4 right-0">
                {errors.resetCode && (
                  <span className="flex items-center">
                    {errors?.resetCode.message}
                  </span>
                )}
              </span>
            </div>
            <div className="mx-auto">
              <button
                onClick={() => setButtonRef("codeSubmit")}
                className="flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      );
    }
  };

  return renderResetPassword();
};

export default ResetPassword;
