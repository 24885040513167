import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToastOpen: false,
  messageTimeout: 4000,
  message: "",
  toastType: "success",
  position: "topCenter",
  direction: "fadeDown",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    closeToast: (state) => {
      state.isToastOpen = false;
    },
    displayToastSuccess: (state, action) => {
      state.isToastOpen = true;
      state.toastType = "success";
      state.messageTimeout =
        action.payload.messageTimeout ?? state.messageTimeout;
      state.message = action.payload.message;
      state.position = action.payload.position ?? state.position;
      state.direction = action.payload.direction ?? state.direction;
    },
    displayToastWarning: (state, action) => {
      state.isToastOpen = true;
      state.toastType = "warning";
      state.messageTimeout =
        action.payload.messageTimeout ?? state.messageTimeout;
      state.message = action.payload.message;
      state.position = action.payload.position ?? state.position;
      state.direction = action.payload.direction ?? state.direction;
    },
  },
});

export const { closeToast, displayToastSuccess, displayToastWarning } =
  toastSlice.actions;
export default toastSlice.reducer;
