import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { IFormInputs } from "../../@types/forminputs";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { signup } from "../../redux/slices/authSlice";

const Register = () => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>();
  const { status } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInputs> = ({ email, password }) => {
    dispatch(
      signup({
        email,
        password,
      })
    )
      .unwrap()
      .then(() => {
        navigate("/auth/emailconfirmation");
      })
      .catch((errorValue) => {
        setError("email", { message: errorValue.message });
      });
  };

  return (
    <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
      <form
        className="flex flex-col space-y-4 sm:border sm:border-slate-500 sm:rounded-lg p-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mx-auto sm:mx-0">
          <h1 className="font-bold text-2xl">Sign Up</h1>
        </div>

        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Email:
          </label>
          <input
            type="text"
            placeholder="pauljohn29@mail.com"
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
              errors.email
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
            {...register("email", {
              required: "You must provide email",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "userOne@email.com",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.email && errors.email.message}
          </span>
        </div>

        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Password:
          </label>
          <input
            type="password"
            placeholder="************"
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
              errors.password
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password too short",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "#testUser0ne",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.password && errors.password.message}
          </span>
        </div>
        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Confirm Password:
          </label>
          <input
            type="password"
            placeholder="************"
            className={`emailappearance-none block w-full bg-gray-200 text-gray-700 border ${
              errors.confirm
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
            {...register("confirm", {
              required: "Please confirm your password",
              validate: {
                confirmPasswordValidate: (value) =>
                  value === getValues().password || "Password is not match",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "#testUser0ne",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.confirm && errors.confirm.message}
          </span>
        </div>

        <div className="mx-auto sm:mx-0 sm:ml-auto">
          <button
            className={`flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${
              status === "pending" && "disabled:opacity-50"
            }`}
            disabled={status === "pending" && true}
          >
            {status === "pending" ? (
              <>
                <Spinner size="w-4 h-4" />
                <span>Loading...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      <div>
        <Link to="/auth/login">
          <p className="font-light text-sm underline">Back to login</p>
        </Link>
      </div>
    </div>
  );
};

export default Register;
