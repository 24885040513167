import { useNavigate } from "react-router-dom";
import {
  FlagIcon,
  RectangleStackIcon,
  RectangleGroupIcon,
  HomeIcon,
  BookOpenIcon,
} from "../assets/icons";
import { useSession } from "../context/SessionContext";
import { useAppDispatch } from "../hooks/useRedux";
import ActiveIcon from "./ActiveIcon";
import NavigationLink from "./NavigationLink";

const Bottombar = () => {
  const { session, setSession } = useSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const renderBottombar = () => {
    return (
      <div className="fixed z-50 bottom-0 w-screen flex items-center text-light bg-stone-800">
        {/* <div className="h-24 flex items-center">
          <img src="/500x500.png" className="w-16" alt="tutela" />
        </div> */}
        <div className="flex w-full justify-evenly font-light py-4">
          <NavigationLink
            to="/"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <HomeIcon className="w-6 h-6" />
                    <span className="text-xs">Home</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <HomeIcon className="w-6 h-6" />
                    <span className="text-xs">Home</span>
                  </>
                }
              />
            }
          />
          {session.isSession && (
            <NavigationLink
              to="/dashboard"
              activeIcon={
                <ActiveIcon
                  isActiveIcon={true}
                  children={
                    <>
                      <RectangleGroupIcon className="w-6 h-6" />
                      <span className="text-xs">Dashboard</span>
                    </>
                  }
                />
              }
              inactiveIcon={
                <ActiveIcon
                  isActiveIcon={false}
                  children={
                    <>
                      <RectangleGroupIcon className="w-6 h-6" />
                      <span className="text-xs">Dashboard</span>
                    </>
                  }
                />
              }
            />
          )}

          {session.isSession && (
            <NavigationLink
              to="/history"
              activeIcon={
                <ActiveIcon
                  isActiveIcon={true}
                  children={
                    <>
                      <BookOpenIcon className="w-6 h-6" />
                      <span className="text-xs">History</span>
                    </>
                  }
                />
              }
              inactiveIcon={
                <ActiveIcon
                  isActiveIcon={false}
                  children={
                    <>
                      <BookOpenIcon className="w-6 h-6" />
                      <span className="text-xs">History</span>
                    </>
                  }
                />
              }
            />
          )}

          <NavigationLink
            to="/coinlisting"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <RectangleStackIcon className="w-6 h-6" />
                    <span className="text-xs">List New Coin</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <RectangleStackIcon className="w-6 h-6" />
                    <span className="text-xs">List New Coin</span>
                  </>
                }
              />
            }
          />
          <NavigationLink
            to="/support"
            activeIcon={
              <ActiveIcon
                isActiveIcon={true}
                children={
                  <>
                    <FlagIcon className="w-6 h-6" />
                    <span className="text-xs">Support</span>
                  </>
                }
              />
            }
            inactiveIcon={
              <ActiveIcon
                isActiveIcon={false}
                children={
                  <>
                    <FlagIcon className="w-6 h-6" />
                    <span className="text-xs">Support</span>
                  </>
                }
              />
            }
          />
        </div>
      </div>
    );
  };

  return <>{renderBottombar()}</>;
};

export default Bottombar;