import { Outlet } from "react-router-dom";

const CoinLayout = () => {
  return (
    <div className="flex-1 flex flex-col bg-stone-900 text-gray-300">
      <Outlet />
    </div>
  );
};

export default CoinLayout;
