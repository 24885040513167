import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InformationCircleIcon } from "../../assets/icons";
import Banner from "../../components/Banner";
import { DepositAddress } from "../../components/DepositAddress";
import HeroSection from "../../components/HeroSection";
import { Modal } from "../../components/Modal";
import ShareDescription from "../../components/ShareDescription";
import Table from "../../components/Table";
import { WithdrawalForm } from "./WithdrawalForm";
import { useSession } from "../../context/SessionContext";
import { useButtonRef } from "../../hooks/useButtonRef";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { logout } from "../../redux/slices/authSlice";
import {
  useGetCoinCollectionQuery,
  useGetCoinTableMutation,
  useGetMasternodeSharingCollectionQuery,
  useGetProtectedCoinQuery,
} from "../../redux/slices/coinCollectionSlice";
import { useGetWithdrawalAddressListQuery } from "../../redux/slices/depositWithdrawalSlice";
import { useSelectUserWalletQuery } from "../../redux/slices/userWalletSlice";
import { ManageAddressContent } from "./ManageAddressContent";
import { WithdrawalContent } from "./WithdrawalContent";
import { Helmet } from "react-helmet";
import { useTopbarTitle } from "../../context/TopbarTitleContext";

const Coin = () => {
  const { setTopbarTitle } = useTopbarTitle();
  const [toggleTab, setToggleTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // rename button ref to general name
  const { buttonRef, setButtonRef } = useButtonRef();
  const isMNRef = useRef();
  const setIsMNRef = (value: any) => (isMNRef.current = value);
  const tableColumnRef = useRef([]);
  const setTableColumnRef = (value: any) => (tableColumnRef.current = value);
  const { coinId } = useParams();
  const { session, setSession } = useSession();
  const { sessionId, deviceId, email } = useAppSelector(
    (state) => state.auth.userEntities
  );
  const { coin: coinBanner } = useGetCoinCollectionQuery(undefined, {
    selectFromResult: ({ data }) => ({
      coin: data?.find((coin: any) => coin.id === coinId),
    }),
  });
  const { data: coinPayload, isSuccess: isCoinPayloadSuccess } =
    useGetProtectedCoinQuery({
      coinId,
      sessionId,
      deviceId,
    });
  const coin = coinPayload?.results[0];
  const { data: wallet, isError: isUserWalletError } = useSelectUserWalletQuery(
    {
      coinId,
      sessionId,
      deviceId,
    }
  );
  const { data: mnData, isError: isMNSharingError } =
    useGetMasternodeSharingCollectionQuery({
      sessionId,
      deviceId,
    });
  const { data: withdrawalAddressList } = useGetWithdrawalAddressListQuery({
    coinId,
    sessionId,
    deviceId,
  });

  const [
    getCoinTable,
    {
      data: coinTable,
      isSuccess: isCoinTableSuccess,
      isLoading: isCoinTableLoading,
    },
  ] = useGetCoinTableMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isCoinPayloadSuccess) {
      setTopbarTitle(coinId);
      setIsMNRef(
        !coin.is_masternode && !coin.is_staking
          ? true
          : coin.is_masternode
          ? true
          : false
      );

      setTableColumnRef([
        {
          Header: isMNRef.current ? "Masternode Address" : "Transaction Id",
          accessor: isMNRef.current ? "masternodeAddress" : "txid",
        },
        {
          Header: isMNRef.current ? "Total Earned" : "Amount",
          accessor: isMNRef.current ? "totalEarned" : "amount",
        },
        {
          Header: isMNRef.current ? "Last Reward Time" : "Block Time",
          accessor: isMNRef.current ? "lastRewardTime" : "blockTime",
        },
        {
          Header: isMNRef.current ? "MN Status" : "Status",
          accessor: isMNRef.current ? "masternodeStatus" : "status",
        },
        {
          Header: isMNRef.current ? "Active Time" : "Confirmations",
          accessor: isMNRef.current ? "activeTime" : "confirmations",
        },
      ]);

      getCoinTable({
        isMNTrue: isMNRef.current,
        coinId,
        sessionId,
        deviceId,
      });
    }
  }, [isCoinPayloadSuccess]);

  useEffect(() => {
    if (isUserWalletError || isMNSharingError) {
      setSession({ ...session, isSession: false });
      dispatch(logout());
      navigate("/");
    }
  }, [isUserWalletError, isMNSharingError]);

  const handleToggleTab = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();

    setToggleTab(index);
  };

  const renderDepositWithdraw = () => {
    switch (toggleTab) {
      case 1:
        return coin?.depositAllowed ? (
          <DepositAddress wallet={wallet} />
        ) : (
          <p>Deposit is disabled</p>
        );

      case 2:
        return coin?.withdrawAllowed ? (
          <WithdrawalForm
            withdrawalAddressList={withdrawalAddressList}
            isModalOpen={isModalOpen}
            openModal={openModal}
            setButtonRef={setButtonRef}
            coinId={coinId}
            sessionId={sessionId}
            deviceId={deviceId}
            coin={coin}
          />
        ) : (
          <p>Withdrawal is disabled</p>
        );
    }
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  const renderModalContent = () => {
    if (buttonRef === "newAddress") {
      return (
        <WithdrawalContent
          coinId={coinId}
          deviceId={deviceId}
          sessionId={sessionId}
          closeModal={closeModal}
          coin={coin}
          emailAddress={email}
        />
      );
    } else if (buttonRef === "manageAddress") {
      return (
        <ManageAddressContent
          withdrawalAddressList={withdrawalAddressList}
          coin={coin}
          closeModal={closeModal}
          sessionId={sessionId}
          deviceId={deviceId}
        />
      );
    }
  };

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{coinId?.toUpperCase()} - Tutela</title>
      </Helmet>

      {isModalOpen && (
        <Modal
          handleModalClose={closeModal}
          modalContent={renderModalContent}
          modalWidth={buttonRef === "manageAddress" ? "w-2/5" : ""}
        />
      )}
      <HeroSection
        className="mx-auto w-full min-h-40 flex flex-col items-center"
        children={
          <div className="flex flex-col space-y-10 w-full items-center">
            <Banner coinData={coinBanner} mnData={mnData} />
            <div className="flex items-center space-x-2">
              <img
                src={coin?.image}
                className="bg-slate-100 w-10 h-10 rounded-full p-1"
              />
              <p className="text-xl uppercase tracking-wider">{coin?.name}</p>
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div className="flex flex-col items-center space-y-6">
                <div className="mb-4 border-b border-slate-700">
                  <ul className="flex flex-wrap text-sm text-center">
                    <li>
                      <button
                        onClick={(event) => handleToggleTab(event, 1)}
                        className={`inline-block p-4 ${
                          toggleTab === 1 ? "border-b-2" : ""
                        }`}
                        type="button"
                      >
                        Deposit
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={(event) => handleToggleTab(event, 2)}
                        className={`inline-block p-4 ${
                          toggleTab === 2 ? "border-b-2" : ""
                        }`}
                        type="button"
                      >
                        Withdrawal
                      </button>
                    </li>
                  </ul>
                </div>
                <>{renderDepositWithdraw()}</>
              </div>

              <div className="col-span-2">
                <div>
                  <div className="flex items-center space-x-1">
                    <InformationCircleIcon className="w-6 h-6" />
                    <h1 className="text-lg">Quick Share System Description</h1>
                  </div>
                  <div className="w-full">
                    <ShareDescription />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <div className="w-full flex flex-col">
        <h1 className="font-bold text-lg">{isMNRef.current ? "Active Masternodes" : "Staking Rewards"}</h1>
        {isCoinTableSuccess && (
          <Table
            listOfItems={coinTable}
            tableColumns={tableColumnRef.current}
            coinTicker={coin?.symbol}
          />
        )}
      </div>
    </div>
  );
};

export default Coin;
