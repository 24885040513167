import { Helmet } from "react-helmet";
const Delisting: React.FC<any> = () => {
  return (
    <div>
      <Helmet>
        <title>Delisting Policy - Tutela</title>
      </Helmet>

      <header className="mb-4 mt-6">
        <h1 className="text-2xl text-center text-white font-bold mb-4">
          Delisting Policy
        </h1>
      </header>
      <section className="flex flex-col space-y-2">
        <article>
          <h1 className="font-bold text-xl">1. Introduction</h1>
          <p>
            Tutela Masternode and Staking Pool Platform is committed to
            providing a secure and reliable platform for staking pool,
            masternode sharing, and web based wallet. As part of this
            commitment, Tutela Masternode and Staking Pool Platform regularly
            reviews the cryptocurrencies listed on the platform to ensure that
            they meet the highest standards of quality, security, and regulatory
            compliance.
          </p>
        </article>

        <article>
          <h1 className="font-bold text-xl">2. Delisting Criteria</h1>
          <p>
            Tutela Masternode and Staking Pool Platform may delist a
            cryptocurrency for any of the following reasons:
          </p>
          <ul className="list-disc ml-4">
            <li>
              <b>Lack of participation</b>: If a cryptocurrency has consistently
              low participation or coin volume in the pool, it may be delisted
              to ensure that Tutela Masternode and Staking Pool Platform users
              have access to a robust and active coin.
            </li>
            <li>
              <b>Security concerns</b>: If a cryptocurrency is deemed to have
              significant security risks, Tutela Masternode and Staking Pool
              Platform may delist it to protect the safety of user assets.
            </li>
            <li>
              <b>Regulatory compliance</b>: Tutela Masternode and Staking Pool
              Platform may delist a cryptocurrency if it is found to be in
              violation of any applicable laws or regulations.{" "}
            </li>
            <li>
              <b>Deception or fraud</b>: If a cryptocurrency is found to have
              been created or promoted through deception or fraud, it may be
              delisted.
            </li>
          </ul>
        </article>

        <article>
          <h1 className="font-bold text-xl">3. Delisting Process</h1>
          <p>
            If Tutela Masternode and Staking Pool Platform determines that a
            cryptocurrency should be delisted, the following process will be
            followed:
          </p>
          <ul className="list-disc ml-4">
            <li>
              <b>Notice</b>: Tutela Masternode and Staking Pool Platform will
              provide notice to the relevant party or parties that the
              cryptocurrency will be delisted. This notice will include the
              reasons for the delisting and the date on which the cryptocurrency
              will be delisted.
            </li>
            <li>
              <b>Withdrawal period</b>: Tutela Masternode and Staking Pool
              Platform will provide a reasonable withdrawal period during which
              users may withdraw their holdings of the delisted cryptocurrency.
            </li>
            <li>
              <b>Delisting</b>: On the date specified in the notice, the
              cryptocurrency will be delisted from Tutela Masternode and Staking
              Pool Platform.
            </li>
          </ul>
        </article>
      </section>
    </div>
  );
};

export default Delisting;
