import { useCallback, useEffect, useRef, useState } from "react";

const useCoins = (coinCollection: any[]) => {
  const [coinList, setCoinList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const headRef = useRef(0);
  const tailRef = useRef(8);

  useEffect(() => {
    fetchCoinList();

    return () => {
      setCoinList([]);
      headRef.current = 0;
      tailRef.current = 8;
    };
  }, []);

  const watchHasMoreCoin = useCallback(() => {
    if (coinList.length === coinCollection.length) return setHasMore(false);
    else return setHasMore(true);
  }, [coinList, hasMore]);

  const fetchCoinList = () => {
    const newCollection = coinCollection.slice(
      headRef.current,
      tailRef.current
    );
    setCoinList((prevCollection) => {
      return [...prevCollection, ...newCollection];
    });

    headRef.current = tailRef.current;
    tailRef.current = tailRef.current + 8;

    watchHasMoreCoin();
  };

  return [coinList, fetchCoinList, hasMore] as const;
};

export default useCoins;
