import { SubmitHandler, useForm } from "react-hook-form";
import { IWithdrawalFormInputs } from "../../@types/forminputs";
import { useAppDispatch } from "../../hooks/useRedux";
import { useProcessWithdrawalMutation } from "../../redux/slices/depositWithdrawalSlice";
import {
  displayToastSuccess,
  displayToastWarning,
} from "../../redux/slices/toastSlice";

export const WithdrawalForm = ({
  withdrawalAddressList,
  isModalOpen,
  openModal,
  setButtonRef,
  coinId,
  coin,
  deviceId,
  sessionId,
}: any) => {
  const [processWithdrawalMutation, { isLoading: isWithdrawalProcessing }] =
    useProcessWithdrawalMutation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IWithdrawalFormInputs>();
  const dispatch = useAppDispatch();

  // const calculateMaxAmount = () => {
  //   setValue("withdrawal_amount", coin?.balance - coin?.withdrawal_fee);
  // };

  const onSubmit: SubmitHandler<IWithdrawalFormInputs> = ({
    withdrawal_address,
    withdrawal_amount,
  }) => {
    processWithdrawalMutation({
      coinId,
      address: withdrawal_address,
      amount: withdrawal_amount,
      sessionId,
      deviceId,
    })
      .unwrap()
      .then((data) => {
        if (data.status === "success") {
          dispatch({
            type: "masternodeSharingCollectionAPI/invalidateTags",
            payload: ["MNCoinList"],
          });
          dispatch(displayToastSuccess({ message: data.message }));
          setValue("withdrawal_amount", 0);
        } else if (data.status === "error") {
          dispatch(
            displayToastWarning({
              message: data.message
            })
          );
        }
      });
  };

  const handleModalEvent = (value: string) => {
    setButtonRef(value);
    if (!isModalOpen) openModal();
  };

  const renderWithdrawalAddressList = () => {
    return (
      <select
        className={`appearance-none w-full block bg-gray-200 text-gray-700 border ${
          errors.withdrawal_address
            ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
            : "border-indigo-500"
        } rounded leading-tight focus:outline-none focus:bg-white`}
        {...register("withdrawal_address", {})}
      >
        <option defaultValue="">Choose Address</option>
        {withdrawalAddressList?.map(
          (
            withdrawalItem: { address: string; label: string },
            index: number
          ) => (
            <option key={index} value={withdrawalItem.address}>
              {withdrawalItem.address} ({withdrawalItem.label})
            </option>
          )
        )}
      </select>
    );
  };

  return (
    <div className="w-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-6"
      >
        <div className="flex items-center space-x-2">
          <label>Address</label>
          <div className="flex w-full space-x-2 ml-auto">
            {renderWithdrawalAddressList()}
            <button
              type="button"
              onClick={() => handleModalEvent("newAddress")}
              className="flex items-center text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
            >
              Add
            </button>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <label>Amount</label>
          <div className="flex w-full space-x-2">
            <input
              className={`appearance-none w-full block bg-gray-200 text-gray-700 border ${
                errors.withdrawal_amount
                  ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                  : "border-indigo-500"
              } rounded leading-tight focus:outline-none focus:bg-white`}
              type="number"
              step="any"
              {...register("withdrawal_amount", {})}
            />
            {/* <button
              type="button"
              onClick={() => {calculateMaxAmount()}}
              className="flex items-center ml-auto text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
            >
              Max
            </button> */}
          </div>
        </div>

        <div className="flex justify-center mt-auto space-x-2">
          <button
            className={`${
              isWithdrawalProcessing && "opacity-75"
            } flex items-center text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center`}
            disabled={isWithdrawalProcessing}
          >
            {!isWithdrawalProcessing ? "Withdrawal" : "Processing..."}
          </button>
          <button
            type="button"
            onClick={() => handleModalEvent("manageAddress")}
            className="$ flex items-center bg-transparent hover:bg-indigo-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-indigo-500 hover:border-transparent rounded"
          >
            Manage Address
          </button>
        </div>
      </form>
    </div>
  );
};
