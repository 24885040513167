import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { authSliceReducer } from "../slices/authSlice/reducer";
import {
  coinCollectionAPI,
  coinListNewAPI,
  coinPriceCollectionAPI,
  coinTableCollectionAPI,
  masternodeSharingCollectionAPI,
  masternodeTableCollectionAPI,
} from "../slices/coinCollectionSlice";
import { userWalletAPI } from "../slices/userWalletSlice";
import { historyCollectionAPI } from "../slices/historyCollectionSlice";
import { withdrawalAPI } from "../slices/depositWithdrawalSlice";
import { default as toastReducer } from "../slices/toastSlice";
import { supportAPI } from "../slices/supportSlice";
import { bannerAPI } from "../slices/bannerSlice";

const userPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["userEntities"],
};

const rootReducer = combineReducers({
  [coinCollectionAPI.reducerPath]: coinCollectionAPI.reducer,
  [bannerAPI.reducerPath]: bannerAPI.reducer,
  [masternodeSharingCollectionAPI.reducerPath]:
    masternodeSharingCollectionAPI.reducer,
  [masternodeTableCollectionAPI.reducerPath]:
    masternodeTableCollectionAPI.reducer,
  [coinTableCollectionAPI.reducerPath]: coinTableCollectionAPI.reducer,
  [coinListNewAPI.reducerPath]: coinListNewAPI.reducer,
  [supportAPI.reducerPath]: supportAPI.reducer,
  [coinPriceCollectionAPI.reducerPath]: coinPriceCollectionAPI.reducer,
  [userWalletAPI.reducerPath]: userWalletAPI.reducer,
  [historyCollectionAPI.reducerPath]: historyCollectionAPI.reducer,
  [withdrawalAPI.reducerPath]: withdrawalAPI.reducer,
  auth: persistReducer(userPersistConfig, authSliceReducer),
  toast: toastReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaulMiddleware) =>
    getDefaulMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      coinCollectionAPI.middleware,
      bannerAPI.middleware,
      masternodeSharingCollectionAPI.middleware,
      masternodeTableCollectionAPI.middleware,
      coinTableCollectionAPI.middleware,
      coinListNewAPI.middleware,
      supportAPI.middleware,
      coinPriceCollectionAPI.middleware,
      userWalletAPI.middleware,
      historyCollectionAPI.middleware,
      withdrawalAPI.middleware,
    ]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
