import { useParams } from "react-router-dom";

interface BannerProps {
  mnData: any,
  coinData: any
}

const Banner = ({ mnData, coinData}: BannerProps) => {
  const { coinId } = useParams();

  return (
    <div className="w-full lg:h-20 grid grid-cols-2 lg:grid-cols-5 lg:border-t lg:border-b border-gray-500 text-xl gap-4">
      <div className="flex flex-col items-center justify-center border-gray-500 lg:border-r bg-gray-500 lg:bg-transparent rounded-2xl lg:rounded-none p-2 lg:p-0">
        <p className="font-light text-xs text-gray-400">Your Balance</p>
        <h1 className="text-md uppercase">{`${mnData?.[`${coinId}`]?.balance} ${coinData?.symbol}`}</h1>
      </div>
      <div className="flex flex-col items-center justify-center lg:border-r border-gray-500 lg:bg-none bg-gray-500 lg:bg-transparent rounded-2xl lg:rounded-none p-2 lg:p-0">
        <p className="font-light text-xs text-gray-400">Your Shares</p>
        <h1 className="text-md">{`${mnData?.[`${coinId}`]?.shares}`}</h1>
      </div>
      <div className="flex flex-col items-center justify-center lg:border-r border-gray-500 lg:bg-none bg-gray-500 lg:bg-transparent rounded-2xl lg:rounded-none p-2 lg:p-0">
        <p className="font-light text-xs text-gray-400">
          Total All User Balance
        </p>
        <h1 className="text-md">{`${
          mnData?.[`${coinId}`]?.totalAllUserBalance
        }`}</h1>
      </div>
      <div className="flex flex-col items-center justify-center lg:border-r border-gray-500 lg:bg-none bg-gray-500 lg:bg-transparent rounded-2xl lg:rounded-none p-2 lg:p-0">
        <p className="font-light text-xs text-gray-400">
          Masternode Collateral
        </p>
        <h1 className="text-md">{coinData?.masternodeCollateral}</h1>
      </div>
      <div className="flex flex-col col-span-2 lg:col-span-1 items-center justify-center border-gray-500 lg:bg-none bg-gray-500 lg:bg-transparent rounded-2xl lg:rounded-none p-2 lg:p-0">
        <p className="font-light text-xs text-gray-400">
          Fee from rewards
        </p>
        <h1 className="text-md">{coinData?.serviceFee}</h1>
      </div>
    </div>
  );
};

export default Banner;
