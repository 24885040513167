import { Link } from "react-router-dom";
import {
  ArrowRightIcon,
  MegaphoneIcon,
  WebsiteIcon,
  WindowIcon,
} from "../assets/icons";

interface CoinCardProps {
  coin: {
    name: string;
    symbol: string;
    image: string;
    id: string;
    roiAnnual: string;
    activeMasternodes: string;
  };
}

const CoinCard = ({ coin }: any) => {
  return (
    <div className="w-full h-48 rounded-xl border border-gray-800 flex flex-col p-4 bg-gray-800">
      <div className="flex items-center">
        <img
          className="w-11 h-11 bg-white p-[3px] rounded-full"
          src={coin.image}
        />
        <p className="font-bold tracking-wide text-lg sm:text-xl ml-2 w-3/4 truncate">
          {coin.name}
        </p>
        <div className="flex items-center ml-auto">
          <Link to={`/coin/${coin.id}`} className="ml-auto ">
            <div className="flex items-center text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center">
              <span>Invest</span>
              <ArrowRightIcon className="w-4 h-4 ml-1" />
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col space-y-1 mt-auto">
        <div className="flex space-x-2 mb-4">
          <p className="font-light uppercase border border-gray-500 w-fit p-1 rounded-lg text-xs">
            {`${coin.serviceFee} FEE`}
          </p>
          <p className="font-light uppercase border border-gray-500 w-fit p-1 rounded-lg text-xs">
            {`ROI: ${coin.earnings.roiAnnual}`}
          </p>
          <p className="font-light uppercase border border-gray-500 w-fit p-1 rounded-lg text-xs">
            {`${coin.activeMasternodes} MN`}
          </p>
        </div>
      </div>
      <div className="flex">
        <ul className="flex w-full items-center space-x-1 sm:space-x-2">
          <li>
            <a
              target="_blank"
              href={coin.links.website}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 p-2 rounded-full">
                <WindowIcon className="w-4 h-4" />
              </div>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href={coin.links.explorer}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 p-2 rounded-full">
                <WebsiteIcon className="w-4 h-4" />
              </div>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href={coin.links.wallet}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 px-2 py-1 rounded-full">
                <i className="fa-brands fa-github-alt w-4 h-4"></i>
              </div>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href={coin.links.discord}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 px-2 py-1 rounded-full">
                <i className="fa-brands fa-discord"></i>
              </div>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href={coin.links.telegram}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 px-2 py-1 rounded-full">
                <i className="fa-brands fa-telegram"></i>
              </div>
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href={coin.links.twitter}
              rel="noopener noreferrer"
            >
              <div className="bg-gray-700 px-2 py-1 rounded-full">
                <i className="fa-brands fa-twitter"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CoinCard;
