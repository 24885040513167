import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationLink from "../../components/NavigationLink";
import { useTopbarTitle } from "../../context/TopbarTitleContext";

const History = () => {
  const { setTopbarTitle } = useTopbarTitle();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/history/deposit");
    setTopbarTitle("History");
  }, []);

  const renderHistoryTab = () => {
    return (
      <>
        <ul className="flex flex-wrap text-sm font-medium text-center space-x-2">
          <li>
            <NavigationLink
              to="/history/deposit"
              activeIcon={
                <span className="inline-block px-4 py-3 text-white bg-indigo-600 rounded-lg active">
                  Deposit
                </span>
              }
              inactiveIcon={
                <span className="inline-block px-4 py-3 text-white rounded-lg">
                  Deposit
                </span>
              }
            />
          </li>
          <li>
            <NavigationLink
              to="/history/withdrawal"
              activeIcon={
                <span className="inline-block px-4 py-3 text-white bg-indigo-600 rounded-lg active">
                  Withdrawal
                </span>
              }
              inactiveIcon={
                <span className="inline-block px-4 py-3 text-white rounded-lg">
                  Withdrawal
                </span>
              }
            />
          </li>
        </ul>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>History - Tutela</title>
      </Helmet>

      {renderHistoryTab()}
      <Outlet />
    </div>
  );
};

export default History;
