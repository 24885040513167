import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const withdrawalAPI = createApi({
  reducerPath: "withdrawalAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ["WithdrawalAddressList"],
  endpoints: (build) => ({
    sendWithdrawalOTP: build.mutation({
      query: (args) => {
        const { coinId, withdrawalAddress, sessionId, deviceId } = args;
        return {
          url: `/protected/withdraw/${coinId}/?act=otp&addr=${withdrawalAddress}`,
          method: "POST",
          body: { sessionId: sessionId, deviceId: deviceId },
        };
      },
    }),
    addNewWithdrawalAddress: build.mutation({
      query: ({
        coinId,
        sessionId,
        deviceId,
        label,
        withdrawalAddress,
        withdrawalVerificationCode,
      }) => ({
        url: `${API_URL}/protected/withdraw/${coinId}/?act=add&label=${label}&code=${withdrawalVerificationCode}&addr=${withdrawalAddress}`,
        method: "POST",
        body: { sessionId: sessionId, deviceId: deviceId },
      }),
      invalidatesTags: ["WithdrawalAddressList"],
    }),
    getWithdrawalAddressList: build.query({
      query: ({ coinId, sessionId, deviceId }) => ({
        url: `${API_URL}/protected/withdraw/${coinId}/?act=list`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
      providesTags: ["WithdrawalAddressList"],
    }),
    deleteWithdrawalAddress: build.mutation({
      query: ({ coinId, addressId, sessionId, deviceId }) => ({
        url: `${API_URL}/protected/withdraw/${coinId}/?act=delete&id=${addressId}`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
      invalidatesTags: ["WithdrawalAddressList"],
    }),
    setDefaultWithdrawalAddress: build.mutation({
      query: ({ coinId, addressId, sessionId, deviceId }) => ({
        url: `${API_URL}/protected/withdraw/${coinId}/?act=setdefaut&id=${addressId}`,
        method: "POST",
        body: { sessionId, deviceId },
      }),
      invalidatesTags: ["WithdrawalAddressList"]
    }),

    processWithdrawal: build.mutation({
      query: ({ coinId, address, amount, sessionId, deviceId }) => ({
        url: `${API_URL}/protected/withdraw/${coinId}/?addr=${address}&amount=${amount}`,
        method: "POST",
        body: { sessionId, deviceId }
      }),
    })
  }),
});

export const {
  useSendWithdrawalOTPMutation,
  useAddNewWithdrawalAddressMutation,
  useGetWithdrawalAddressListQuery,
  useDeleteWithdrawalAddressMutation,
  useSetDefaultWithdrawalAddressMutation,
  useProcessWithdrawalMutation
} = withdrawalAPI;
