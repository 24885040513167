import Table from "../../components/Table";
import { useAppSelector } from "../../hooks/useRedux";
import { useGetCoinCollectionQuery } from "../../redux/slices/coinCollectionSlice";
import { useGetWithdrawalHistoryQuery } from "../../redux/slices/historyCollectionSlice";

const WithdrawalHistory = () => {
  const { deviceId, sessionId } = useAppSelector(
    (state) => state.auth.userEntities
  );
  const {
    data: withdrawalCollection,
    isSuccess: isWithdrawalHistoryCollectionSuccess,
  } = useGetWithdrawalHistoryQuery(
    { sessionId, deviceId },
    { refetchOnMountOrArgChange: true }
  );
  const { data: coinCollection } = useGetCoinCollectionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const withdrawalHistoryTableColumns = [
    {
      Header: "Coin",
      accessor: "name",
      Cell: ({ row: item }: any) => {
        const coin = coinCollection?.find(
          (coin: any) => coin.id === item.original.id
        );
        return (
          <div className="flex items-center space-x-2">
            <img
              src={coin?.image}
              alt="logo"
              className="w-6 h-6 bg-white p-[2px] rounded-full"
            />
            <p>{`${item.original.id}`}</p>
          </div>
        );
      },
    },

    {
      Header: "Transaction ID",
      accessor: "txid",
      Cell: ({ row: item }: any) => (
        <>
          <p className="truncate">{item.original.txid}</p>
        </>
      ),
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Date Added",
      accessor: "timestamp",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row: item }: any) => {
        const coin = coinCollection?.find(
          (coin: any) => coin.id === item.original.id
        );

        return (
          <p className="uppercase">
            {item.original.amount} {coin?.symbol}
          </p>
        );
      },
    },
    {
      Header: "Status",
      accessor: "depositStatus",
      Cell: ({ row: item }: any) => (
        <p className="uppercase">{item.original.withdrawalStatus}</p>
      ),
    },
  ];

  return (
    <div className="flex-1 w-full flex flex-col mx-auto">
      <div className="w-full flex flex-col">
        {isWithdrawalHistoryCollectionSuccess && (
          <Table
            listOfItems={withdrawalCollection}
            tableColumns={withdrawalHistoryTableColumns}
          />
        )}
      </div>
    </div>
  );
};

export default WithdrawalHistory;
