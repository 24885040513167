import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const supportAPI = createApi({
  reducerPath: "supportAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    sendSupportRequest: build.mutation({
      query: (payload) => ({
        url: "/support",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useSendSupportRequestMutation } = supportAPI;