import { createContext, ReactNode, SetStateAction, useContext, useState } from "react";

interface Props {
  children?: ReactNode;
}
interface ITopbarTitleContextProps {
  topbarTitle: any;
  setTopbarTitle: any;
}

const TopbarTitleContext = createContext<ITopbarTitleContextProps | null>(null);

export const TopbarTitleProvider = ({ children }: Props) => {
  const [topbarTitle, setTopbarTitle] = useState("Title");

  const topbarTitleContextValue = () => ({
    topbarTitle,
    setTopbarTitle,
  });

  return (
    <TopbarTitleContext.Provider value={topbarTitleContextValue()}>
      {children}
    </TopbarTitleContext.Provider>
  );
};

export const useTopbarTitle = () => {
    const context = useContext(TopbarTitleContext);

    if (!context) throw new Error("must be in context");
    return context;
}