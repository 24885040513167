import { Backdrop } from "./Backdrop";

interface ModalProps {
  handleModalClose: Function;
  modalContent: Function;
  modalWidth? : string;
}
export const Modal = ({ modalWidth = "w-1/3", handleModalClose, modalContent }: ModalProps) => {
  return (
    <Backdrop handleModalClose={handleModalClose}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${modalWidth} h-96 p-6 flex flex-col bg-slate-700 border-slate-700 drop-shadow-2xl rounded-2xl text-slate-600`}
      >
        {modalContent()}
      </div>
    </Backdrop>
  );
};
