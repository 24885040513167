import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [year, setYear] = useState("");

  useEffect(() => {
    const thisYear = new Date().getFullYear().toString();
    setYear(thisYear);
  }, []);
  return (
    <footer className="w-full flex space-x-12 justify-end p-10 mb-12 lg:mb-0">
      <div className="col-span-2"></div>

      <div className="flex flex-col space-y-4 text-sm items-start">
        <label className="font-bold tracking-wide">Social Media</label>
        <a href="https://twitter.com/TutelaCoin" target="_blank">
          <span>Twitter</span>
        </a>
        <a href="https://discord.gg/dj4P8qWgbZ" target="_blank">
          <span>Discord</span>
        </a>
      </div>

      <div className="flex flex-col space-y-4 text-sm items-start">
        <label className="font-bold tracking-wide">Privacy & Terms</label>
        <Link to="/privacy-policies">
          <span>Privacy Policy</span>
        </Link>
        <Link to="/delisting">
          <span>Delisting Policy</span>
        </Link>
      </div>

      <div className="flex flex-col space-y-4 text-sm items-end justify-end">
        <p>&copy; {`${year} Tutela. All Rights Reserved`}</p>
      </div>
    </footer>
  );
};

export default Footer;
