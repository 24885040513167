import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks/useRedux";
import { useSendCoinListingMutation } from "../../redux/slices/coinCollectionSlice";
import { displayToastSuccess } from "../../redux/slices/toastSlice";

export const CoinList = () => {
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm<any>();

  const [sendCoinListing, { data: coinListingRes, isLoading, isError }] =
    useSendCoinListingMutation();
  const dispatch = useAppDispatch();

  const onSubmit = ({
    coinName,
    coinTicker,
    coinCollateral,
    coinMasternodePort,
    coinlinkCoinLogo,
    coinAddnodes,
    websiteLink,
    explorerLink,
    githubLink,
    discordLink,
    telegramLink,
    twitterLink,
    applicantFullname,
    applicantEmail,
  }: any) => {
    const jsonified = JSON.stringify({
      coinName,
      coinTicker,
      coinCollateral,
      coinMasternodePort,
      coinlinkCoinLogo,
      coinAddnodes,
      websiteLink,
      explorerLink,
      githubLink,
      discordLink,
      telegramLink,
      twitterLink,
      applicantFullname,
      applicantEmail,
    });

    sendCoinListing(jsonified)
      .unwrap()
      .then((res: any) => {
        dispatch(displayToastSuccess({ message: res.message }));
      })
      .catch((err) => {
        // displayToastWarning({ message: err.})
      });
  };

  return (
    <div className="flex flex-col flex-1">
      <Helmet>
        <title>New Coin Listing - Tutela</title>
      </Helmet>
      
      <div className="mb-4">
        <h1 className="text-2xl lg:text-3xl font-black dark:text-white">
          New Coin Listing
        </h1>
        <p className="text-gray-500 dark:text-white">
          Please fill the form below
        </p>
      </div>
      <div>
        <form
          className="lg:relative flex flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <section className="w-full flex flex-col lg:flex-row lg:space-x-8">
            {/* coin details */}
            <div>
              <h2 className="text-2xl mb-4 bg-gray-800 py-2 px-3 rounded-lg">
                Coin Details
              </h2>
              <article className="flex flex-col lg:flex-row lg:space-x-4">
                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Coin Name
                  </label>
                  <input
                    {...register("coinName", {
                      required: "Field must not be empty",
                    })}
                    type="text"
                    className={`appearance-none block bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                  />
                  <div className="h-5 flex justify-end">{/* error */}</div>
                </div>

                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Coin Ticker
                  </label>
                  <input
                    {...register("coinTicker", {
                      required: "Field must not be empty",
                    })}
                    type="text"
                    className={`appearance-none block bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                  />
                  <div className="h-5 flex justify-end">{/* error */}</div>
                </div>
              </article>

              <article className="flex space-x-4 w-full">
                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Collateral
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("coinCollateral", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Masternode Port
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("coinMasternodePort", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>
              </article>

              <div className="flex flex-col">
                <label className="text-sm mb-2 dark:text-white">
                  Link To Coin Logo
                </label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                    {...register("coinlinkCoinLogo", {
                      required: "Field must not be empty",
                    })}
                  />
                </div>
                <div className="h-5 flex justify-end">
                  <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                    {/* error */}
                  </span>
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-sm mb-2 dark:text-white">
                  Recommended Addnodes
                </label>
                <div className="relative flex items-center">
                  <textarea
                    rows={6}
                    className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                    {...register("coinAddnodes", {
                      required: "Field must not be empty",
                    })}
                  />
                </div>
                <div className="h-5 flex justify-end">
                  <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                    {/* error */}
                  </span>
                </div>
              </div>
            </div>
            {/* links */}
            <div>
              <h2 className="text-2xl mb-4 bg-gray-800 py-2 px-3 rounded-lg">
                Links
              </h2>
              <div className="grid grid-cols-2 gap-x-4">
                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Website
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("websiteLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2 dark:text-white">
                    Explorer
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("explorerLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2 dark:text-white">Github</label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("githubLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2 dark:text-white">
                    Discord
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("discordLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2 dark:text-white">
                    Telegram
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("telegramLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2 dark:text-white">
                    Twitter
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="text"
                      className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                      {...register("twitterLink", {
                        required: "Field must not be empty",
                      })}
                    />
                  </div>
                  <div className="h-5 flex justify-end">
                    <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                      {/* error */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* applicant details */}
            <div>
              <h2 className="text-2xl mb-4 bg-gray-800 py-2 px-3 rounded-lg">
                Applicant Details
              </h2>
              <article className="flex flex-col lg:flex-row lg:space-x-4">
                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Full Name
                  </label>
                  <input
                    {...register("applicantFullname", {
                      required: "Field must not be empty",
                    })}
                    type="text"
                    className={`appearance-none block bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                  />
                  <div className="h-5 flex justify-end">{/* error */}</div>
                </div>

                <div className="flex flex-col w-full">
                  <label className="text-sm mb-2 dark:text-white">
                    Email Address
                  </label>
                  <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-700 text-white border ${
                      errors.applicantEmail
                        ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                        : "border-indigo-500"
                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
                    {...register("applicantEmail", {
                      required: "You must provide email",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                  />

                  <div className="h-5 flex justify-end">{/* error */}</div>
                </div>
              </article>
            </div>
          </section>

          <div className="my-6 lg:absolute lg:right-0 lg:-top-24">
            <button
              className={`flex items-center bg-indigo-500 hover:bg-indigo-400 text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}
            >
              <span className="tracking-wide font-bold">Submit Request</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
