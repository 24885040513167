import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const bannerAPI = createApi({
  reducerPath: "bannerAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ["WithdrawalAddressList"],
  endpoints: (build) => ({
    getAnnouncement: build.query({
      query: () => {
        return {
          url:  "/announcements",
        };
      },
    })
})
});

export const {
    useGetAnnouncementQuery
} = bannerAPI;
