import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Bottombar from "../../components/Bottombar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import useDimension from "../../hooks/useDimension";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { displayToastWarning } from "../../redux/slices/toastSlice";

const MainLayout = () => {
  const [dimension] = useDimension();
  const { userEntities } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userEntities?.isVerified === false) {
      dispatch(
        displayToastWarning({
          message: "Your email is not verified, please re-login",
        })
      );
    }
  }, [userEntities]);

  return (
    <div className="bg-stone-900 text-gray-300 min-h-screen">
      {dimension[0] <= 1024 ? <Bottombar /> : <Sidebar />}
      <div className="lg:w-[calc(100vw_-_7rem)] min-h-screen px-4 ml-auto">
          <Topbar dimensionWidth={dimension[0]} />
          <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
