import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../../context/SessionContext";

const AuthLayout = () => {
  const { session } = useSession();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (session.isSession) {
      navigate("/");
    }
  }, []);

  const location = pathname.split("/").pop();

  if (location === "login") {
    return (
      <div className="flex flex-col bg-stone-900 text-gray-300 h-screen">
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 flex-1">
          <div className="flex flex-col items-center justify-center mt-10 sm:mt-0">
            <img src="/500x500.png" alt="hero" className="w-28 sm:w-96" />
          </div>
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-stone-900 text-gray-300 w-full min-h-screen">
      <Helmet>
        <title>Tutela</title>
      </Helmet>

      <Outlet />
    </div>
  );
};

export default AuthLayout;
