import {
  Row,
  TableInstance,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseSortByInstanceProps,
  useTable,
  useSortBy,
} from "react-table";
import {
  ChangeEvent,
  Fragment,
  JSXElementConstructor,
  Key,
  MouseEvent,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useMemo,
} from "react";
import {
  ArrowDownSmallIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpDownIcon,
  ArrowUpSmallIcon,
  SearchIcon,
} from "../assets/icons";
import { useParams } from "react-router-dom";

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> &
  UseGlobalFiltersInstanceProps<T> & {
    state: UsePaginationState<T> & UseGlobalFiltersState<T>;
  };

const Table = ({ listOfItems, tableColumns, coinTicker }: any) => {
  const { coinId } = useParams();
  const data: any = useMemo(() => {
    return Array.isArray(listOfItems) ? listOfItems : listOfItems.stakingData;
  }, []);
  const columns: any = useMemo(() => tableColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    gotoPage,
    pageCount,
    state: pageState,
    setGlobalFilter,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<any>;

  const { pageIndex, globalFilter } = pageState;

  const handleNextItemsEvent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    nextPage();
  };

  const handlePrevItemsEvent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    previousPage();
  };

  const handleFirstPageJumpEvent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    gotoPage(0);
  };

  const handleLastPageJumpEvent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    gotoPage(pageCount - 1);
  };

  const handleSearchFilterEvent = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    let searchText = event.target.value;
    setGlobalFilter(searchText);
  };

  return (
    <div className="overflow-scroll scrollbar-hide">
      <div className="flex flex-row-reverse items-center lg:flex-row justify-between my-3">
        <div className="flex space-x-1 uppercase">
          { listOfItems.totalReward ? `total reward: ${listOfItems.totalReward} ${coinTicker}` : ""}
        </div>
        <div className="flex relative space-x-1">
          <input
            type="text"
            className="bg-gray-700 h-10 rounded-lg border-none text-sm pr-9"
            value={globalFilter}
            placeholder="Quick search..."
            onChange={handleSearchFilterEvent}
          />
          <button className="absolute top-2 right-2 text-gray-400">
            <SearchIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
      <table
        {...getTableProps()}
        className="w-full text-sm text-left text-gray-400"
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-700 text-gray-400">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column: any, index: Key | null | undefined) => {
                  return (
                    <Fragment key={index}>
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="py-3 px-6"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{column.render("Header")}</span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownSmallIcon className="w-4 h-4 text-sky-500" />
                            ) : (
                              <ArrowUpSmallIcon className="w-4 h-4 text-sky-500" />
                            )
                          ) : (
                            <ArrowUpDownIcon className="w-4 h-4 text-sky-500" />
                          )}
                        </div>
                      </th>
                    </Fragment>
                  );
                }
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row<object>, index, { length }) => {
            prepareRow(row);

            return (
              <tr
                {...row.getRowProps()}
                className={`${
                  length - 1 !== index && "border-b"
                } bg-gray-800 border-gray-700`}
              >
                {row.cells.map(
                  (cell: {
                    getCellProps: () => JSX.IntrinsicAttributes;
                    render: (
                      arg0: string
                    ) =>
                      | string
                      | number
                      | boolean
                      | ReactFragment
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | ReactPortal
                      | null
                      | undefined;
                  }) => {
                    return (
                      <td {...cell.getCellProps()} className="py-4 px-6">
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <nav className="w-full flex flex-col-reverse lg:flex-row items-center justify-between my-4">
        <span className="text-sm font-normal text-gray-500 my-4 lg:my-0">
          Page <span className="font-semibold text-white">{pageIndex + 1}</span>{" "}
          of{" "}
          <span className="font-semibold text-white">{pageOptions.length}</span>
        </span>
        <div className="flex items-center space-x-3">
          <button
            onClick={handleFirstPageJumpEvent}
            disabled={!canPreviousPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium border rounded-lg bg-gray-800 border-indigo-500 text-gray-400 hover:bg-gray-700 dark:hover:text-white"
          >
            <span>First Page</span>
          </button>

          <button
            onClick={handlePrevItemsEvent}
            disabled={!canPreviousPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium border rounded-lg bg-gray-800 border-indigo-500 text-gray-400 hover:bg-gray-700 dark:hover:text-white"
          >
            <ArrowLeftIcon className="w-5 h-5" />
          </button>

          <button
            onClick={handleNextItemsEvent}
            disabled={!canNextPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium border rounded-lg bg-gray-800 border-indigo-500 text-gray-400 hover:bg-gray-700 dark:hover:text-white"
          >
            <ArrowRightIcon className="w-5 h-5" />
          </button>

          <button
            onClick={handleLastPageJumpEvent}
            disabled={!canNextPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium border rounded-lg bg-gray-800 border-indigo-500 text-gray-400 hover:bg-gray-700 dark:hover:text-white"
          >
            <span>Last Page</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Table;
