import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { verifyEmail } from "../../redux/slices/authSlice";

interface IEmailConfirmForm {
  emailConfirm: string;
}

const EmailConfirmation = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IEmailConfirmForm>();

  const { status } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IEmailConfirmForm> = ({ emailConfirm }) => {
    try {
      dispatch(verifyEmail({ emailConfirm }))
        .unwrap()
        .then(() => {
          navigate("/");
        });
    } catch (error: any) {
      setError("emailConfirm", {
        type: "custom",
        message: error.message ? error.message : error,
      });
    }
  };

  return (
    <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
      <form
        className="flex flex-col space-y-4 rounded-lg p-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <h1 className="font-bold text-2xl">Email Confirmation</h1>
        </div>
        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Code Confirmation:
          </label>
          <input
            type="text"
            placeholder="************"
            autoComplete="off"
            className={`appearance-none block w-full bg-gray-700 text-white border ${
              errors.emailConfirm
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            {...register("emailConfirm", {

              required: "You must provide email confirmation code",
              minLength: { value: 8, message: "Code that you provided is too short" },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "XGDPIHCBKV",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.emailConfirm && errors.emailConfirm.message}
          </span>
        </div>
        <div className="mx-auto">
          <button className="flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            {status === "pending" ? (
              <>
                <Spinner size="w-4 h-4" />
                <span>Loading...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailConfirmation;
