import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { resendVerifyEmail } from "../../redux/slices/authSlice";
import { displayToastSuccess } from "../../redux/slices/toastSlice";

interface IResendEmailConfirmForm {
  email: string;
}

const ResendEmailConfirmation = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IResendEmailConfirmForm>();

  const { status } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IResendEmailConfirmForm> = ({ email }) => {
    try {
      dispatch(resendVerifyEmail({ userEmail: email }))
        .unwrap()
        .then(({ message }) => {
          navigate("/auth/emailconfirmation");
          dispatch(displayToastSuccess({ message: message }));
        });
    } catch (error: any) {
      setError("email", {
        type: "custom",
        message: error.message ? error.message : error,
      });
    }
  };

  return (
    <div className="flex-1 flex flex-col space-y-2 items-center justify-center mx-auto w-full sm:w-2/6">
      <form
        className="flex flex-col space-y-4 rounded-lg p-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <h1 className="font-bold text-2xl">Email Confirmation</h1>
          <p className="text-gray-500">
            Enter your email below to get the email verification code
          </p>
        </div>
        <div className="relative flex flex-col">
          <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2">
            Email:
          </label>
          <input
            type="text"
            placeholder="pauljohn29@mail.com"
            className={`appearance-none block w-full bg-gray-700 text-white border ${
              errors.email
                ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                : "border-indigo-500"
            } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
            {...register("email", {
              required: "You must provide email",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
              value:
                process.env.REACT_APP_ENV === "staging" ||
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "userOne@email.com",
            })}
          />
          <span className="text-xs text-rose-500 absolute -bottom-2 right-0">
            {errors.email && errors.email.message}
          </span>
        </div>
        <div className="mx-auto">
          <button className="flex items-center bg-transparent hover:bg-blue-500 text-indigo-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            {status === "pending" ? (
              <>
                <Spinner size="w-4 h-4" />
                <span>Loading...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResendEmailConfirmation;
