import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL =
  APP_ENV === "staging" || APP_ENV === "production"
    ? process.env.REACT_APP_LIVE_SERVER
    : process.env.REACT_APP_TEST_SERVER;

export const historyCollectionAPI = createApi({
  reducerPath: "historyCollectionAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  endpoints: (build) => ({
    getDepositHistory: build.query({
      query: ({ sessionId, deviceId }) => {
        return {
          url: `/protected/history/deposits`,
          method: "POST",
          body: { sessionId, deviceId },
        };
      },
    }),

    getWithdrawalHistory: build.query({
      query: ({ sessionId, deviceId }) => {
        return {
          url: `/protected/history/withdrawals`,
          method: "POST",
          body: { sessionId, deviceId },
        };
      },
    }),

    getPayoutHistoryByCoin: build.query({
      query: ({ coinId, sessionId, deviceId }) => {
        return {
          url: `/protected/history/payouts/?id=${coinId}`,
          method: "POST",
          body: {
            sessionId,
            deviceId,
          }
        };
      },
    }),
  }),
});

export const { useGetDepositHistoryQuery, useGetWithdrawalHistoryQuery, useGetPayoutHistoryByCoinQuery } =
  historyCollectionAPI;
