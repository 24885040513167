import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { closeToast } from "../redux/slices/toastSlice";

const Toast = () => {
  const {
    isToastOpen,
    messageTimeout,
    message,
    toastType,
    position,
    direction,
  } = useAppSelector((state) => state.toast);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const clearToast = () =>
      setTimeout(() => dispatch(closeToast()), messageTimeout);

    if (isToastOpen) {
      clearToast();
    }

    return () => clearTimeout(clearToast());
  }, [isToastOpen]);

  const renderToastIcon = (toastType: string) => {
    switch (toastType) {
      case "warning":
        return (
          <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg bg-orange-700 text-orange-200">
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
        );
      default:
        // success
        return (
          <div
            className={`text-indigo-500 bg-indigo-100 inline-flex flex-shrink-0 justify-center items-center w-8 h-8 rounded-lg bg-green-800 text-green-200`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>

            <span className="sr-only">Check icon</span>
          </div>
        );
    }
  };

  const toastPositions: any = {
    bottomCenter: "bottom-10",
    topCenter: "top-10",
  };

  const variants: any = {
    fadeUp: {
      initial: {
        y: 100,
        opacity: 0,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      },
      exit: {
        opacity: 0,
      },
    },
    fadeDown: {
      initial: {
        y: "-100vh",
        opacity: 0,
      },
      visible: {
        y: "0",
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      },
      exit: {
        opacity: 0,
      },
    },
  };

  return (
    <AnimatePresence>
      {isToastOpen && (
        <div
          className={`z-50 absolute w-full ${toastPositions[position]} flex items-center justify-center`}
        >
          <motion.div
            key={toastType}
            variants={variants[direction]}
            initial="initial"
            animate="visible"
            exit="exit"
            className={`flex  p-4 mb-4 w-full max-w-xs rounded-lg shadow items-center text-gray-400 bg-gray-800`}
            role="alert"
          >
            {renderToastIcon(toastType)}
            <div className="ml-3 text-sm font-normal text-white">{message}</div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 text-gray-500 hover:text-white bg-gray-800 hover:bg-gray-700"
              data-dismiss-target="#toast-success"
              aria-label="Close"
              onClick={() => dispatch(closeToast())}
            >
              <span className="sr-only">Close</span>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Toast;
