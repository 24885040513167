import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks/useRedux";
import { useSendSupportRequestMutation } from "../../redux/slices/supportSlice";
import { displayToastSuccess } from "../../redux/slices/toastSlice";

const Support: React.FC = () => {
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm<any>();

  const [sendSupportRequest, { data: supportRequestRes, isLoading, isError }] =
    useSendSupportRequestMutation();
  const dispatch = useAppDispatch();

  const onSubmit = ({ supportEmail, supportSubject, supportMessage }: any) => {
    const jsonified = JSON.stringify({
      supportEmail,
      supportSubject,
      supportMessage,
    });

    sendSupportRequest(jsonified)
      .unwrap()
      .then((res) => {
        dispatch(displayToastSuccess({ message: res.message }));
      })
      .catch((err) => {
        //
      });
  };

  return (
    <div className="flex flex-col flex-1">
      <Helmet>
        <title>Support - Tutela</title>
      </Helmet>
      
      <div className="mb-4">
        <h1 className="text-2xl lg:text-3xl font-black dark:text-white">
          Customer Support
        </h1>
        <p className="text-gray-500 dark:text-white">
          Please fill the form below
        </p>
      </div>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="w-full flex flex-col lg:flex-row lg:space-x-8">
            <div className="w-1/2">
              <div className="w-1/2 flex flex-col">
                <label className="text-sm mb-2 dark:text-white">Email</label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-700 text-white border ${
                      errors.supportEmail
                        ? "bg-rose-100 focus:bg-rose-100 border-rose-500 text-rose-600 focus:border-rose-500 focus:ring-rose-500"
                        : "border-indigo-500"
                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none`}
                    {...register("supportEmail", {
                      required: "You must provide email",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                </div>
                <div className="h-5 flex justify-end">
                  <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                    {/* error */}
                  </span>
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-sm mb-2 dark:text-white">Subject</label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                    {...register("supportSubject", {
                      required: "Field must not be empty",
                    })}
                  />
                </div>
                <div className="h-5 flex justify-end">
                  <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                    {/* error */}
                  </span>
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-sm mb-2 dark:text-white">Message</label>
                <div className="relative flex items-center">
                  <textarea
                    rows={6}
                    className={`appearance-none block w-full bg-gray-700 text-white-700 border border-indigo-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600`}
                    {...register("supportMessage", {
                      required: "Field must not be empty",
                    })}
                  />
                </div>
                <div className="h-5 flex justify-end">
                  <span className="text-right text-sm text-rose-500 dark:text-rose-300 pt-1">
                    {/* error */}
                  </span>
                </div>
              </div>
            </div>
          </section>

          <div className="my-6">
            <button
              className={`flex items-center bg-indigo-500 hover:bg-indigo-400 text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}
            >
              <span className="tracking-wide font-bold">Submit Request</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Support;
